<section class="car-description" *ngIf="vehicle">
    <div class="car-info">
        <div class="car-content-info">
            <h1 class="brand-title">{{vehicle.marca}}
                <span class="model">{{vehicle.modelo}}</span>
                <span class="version">{{vehicle.version}}</span>
            </h1>

            <ul class="description">
                <!--<li class="year">{{vehicle.attributes.date}}</li>
                <li class="kilometers">{{vehicle.attributes.kilometers}}</li>
                <li class="cv">{{vehicle.attributes.cv}}</li>
                <li class="fuelType">{{vehicle.attributes.fuelType}}</li>
                <li class="transmision">{{vehicle.attributes.transmision}}</li>-->
            </ul>
        </div>
    </div>

    <div class="car-price">
        <span>desde</span>
        <ul class="price-info">
            <li class="price" *ngIf="!vehicle.offered_price">{{vehicle.precio | currency:'EUR':'symbol':'3.0'}}</li>
            <li class="price" *ngIf="vehicle.offered_price">{{vehicle.offered_price | currency:'EUR':'symbol':'3.0'}}</li>
            <li class="financed_price" *ngIf="vehicle.financed_price">{{vehicle.financed_price}}/mes</li>
        </ul>

        <a href="#" class="button primary-btn">¡Me interesa!</a>

    </div>
</section>