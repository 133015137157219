<div class="tab-price-container">
    <p>Precio al contado inicial<span class="price">{{vehicle.precio | currency:'EUR':'symbol':'3.0'}}</span></p>
    <div class="discount-dto">
        <div class="checkbox pull-left">
            <label class="container">
                <label class="title">
                    ¡Ola de Descuentos!
                </label>
            <input type="checkbox" (change)="changeDiscount($event)" checked>
            <span class="checkmark"></span>
            </label>
        </div>
        <b class="discount-price">
            -{{discount | currency:'EUR':'symbol':'3.0'}}
        </b>
    </div>
    <div class="discount-dto">
        <div class="checkbox pull-left">
            <label class="container">
                <label class="title">
                    15 días o 1000km para probarlo
                </label>
            <input type="checkbox" checked disabled>
            <span class="checkmark"></span>
            </label>
        </div>
        <b class="discount-price">
            GRATIS
        </b>
    </div>
    <div class="quarranty-dto">
        <div class="checkbox pull-left">
            <label class="container">
                <label class="title">
                    1 año de garantía
                </label>
            <input type="checkbox" checked disabled>
            <span class="checkmark"></span>
            </label>
        </div>
        <b class="discount-price">
            GRATIS
        </b>
    </div>
    <div class="price-dto">
        <p>Precio final<span class="price">{{totalprice | currency:'EUR':'symbol':'3.0'}} <span>IVA deducible</span></span>
        </p>
    </div>
</div>
<div class="tab-contact">
    <div class="workshop-info">
        <!--
        <h1>{{_ss.workShopConfig.site_title}}</h1>
        <p>{{workShopConfig.address}}</p>
        <a href="tel:workShopConfig.templateOptions.phone">{{workShopConfig.templateOptions.phone}}</a>
        <a href="mailto:workShopConfig.templateOptions.email">{{workShopConfig.templateOptions.email}}</a>-->
    </div>
    <div class="form">
        <textarea>{{message.message}}</textarea>
        <input type="text" [(ngModel)]="message.name" placeholder="Nombre y Apellidos*">
        <input type="email" [(ngModel)]="message.email" placeholder="Email">
        <input type="phone" [(ngModel)]="message.phone" placeholder="Teléfono">
        <button type="submit" class="primary-btn">ME INTERESA</button>
    </div>
</div>