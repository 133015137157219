<div id="PubMiddle">
    <form id="calculate_form">
        <input type="hidden" id="asociado_coche" name="asociado_coche" value="false">
        <!--       INSERTAR EL VALOR DEL SITIO ORIGEN  EN value="" -->
        <input type="hidden" id="sitio_origen" name="sitio_origen" value="fagenauto">
        <!--       =============================================== -->
        <input type="hidden" id="url" name="url" value="">
        <input type="hidden" id="make" name="make" value="">
        <input type="hidden" id="model" name="model" value="">
        <input type="hidden" id="antiguedad" name="antiguedad" value="">
        <input type="hidden" id="asoc" name="asoc" value="true">
        <input type="hidden" id="seguro" name="seguro" value="false">
        <div class="container">
            <div class="scftitular_contenedor">
                <div class="scftitular">
                    Simula tu financiación
                </div>
            </div>
            <div class="respuesta">
                <div id="loading"></div>
                <div class="icono_coche"></div>
                <input type="text" id="salida" value="0" readonly><span class="unidad_medida2">&euro;/mes*</span>
                <div id="respuesta" style="display:none"></div>
                <!--              <a class="redirectDerecha" href="#" target="_blank"><div id="solicitar" class="solicitalo">SOLIC&Iacute;TALO AQU&Iacute;&nbsp; ></div></a> -->
            </div>

            <div class="bloquecalc">
                <input type="hidden" id="importe" name="importe" value="">

                <div class="importe_financiar_contenedor">
                    <div class="importe_financiar_left">
                        Entrada:
                    </div>

                    <div class="content_slider">

                        <input type="text" id="format_entrada" class="datos_entrada"><span class="unidad_medida">&euro;</span>
                        <input type="hidden" id="entrada" name="entrada" value="">
                        <div id="entrada_slider"></div>
                    </div>
                </div>
                <div class="importe_financiar_plazo">
                    <div class="importe_financiar_left">
                        Plazo:
                    </div>
                    <div class="content_slider_plazo">
                        <input type="text" id="plazo" name="plazo" class="datos_entrada" value="24">
                        <span class="unidad_medida">Meses</span>
                        <div id="plazo_slider"></div>
                    </div>
                </div>

                <div class="separador_blanco"></div>
                <div class="franja_azul">
                    <div class="precio_coche_contenedor">
                        <div class="precio_coche_left">Precio del coche:</div>
                        <div class="precio_coche_right"><span id="format_importe"></span>&nbsp;&euro;</div>
                    </div>
                    <div class="precio_coche_contenedor">
                        <div class="precio_coche_left">Año de matriculación:</div>
                        <div class="precio_coche_right"><span id="antiguedad_display"></span></div>
                    </div>
                    <div class="precio_coche_contenedor">
                        <div class="precio_coche_left">Importe a financiar:</div>
                        <div class="precio_coche_right"><span id="financiar"></span>&nbsp;<span style="font-weight: bold;">&euro;</span></div>
                    </div>
                </div>

            </div>
            <!--fin banner1-->
            <div class="mas_info">
                <!-- <input type="button" class="button button3" value="Solicitar más info"> -->
            </div>


            <div class="condiciones_contenedor">
                <div class="condiciones">
                    <p>*Tipo deudor:<span id="tin"></span>%. <span class="tae">TAE:<span id="tae" class="tae"></span>%.</span> Importe total del cr&eacute;dito:<span id="importe_credito"></span>&euro;. Comisi&oacute;n de apertura:<span id="comision_porciento"></span>%
                        sobre el importe total cr&eacute;dito. Importe total adeudado:<span id="importe_adeudado"></span>&euro;. <span class="con_seguro"></span><br/> <strong><span class="financiacion">Financiaci&oacute;n ofrecida por Santander Consumer E.F.C., S.A.</span></strong>
                    </p>
                </div>
                <!--fin condiciones-->
            </div>
            <div class="logoscf"></div>


        </div>
        <!--fin container-->

    </form>
</div>