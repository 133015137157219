import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  public isOpen = false;
  constructor(
    public _ss: SharingService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isOpen = false;
      }
    });
  }

  openMenu() {
    console.log("abriendo....");
    this.isOpen = !this.isOpen;
  }
  
  goTo(url: string) {
    this.isOpen = false;
    this.router.navigate([url]);
  }

}
