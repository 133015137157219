import { Component, OnInit} from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-workshop-description',
  templateUrl: './workshop-description.component.html',
  styleUrls: ['./workshop-description.component.scss']
})
export class WorkshopDescriptionComponent implements OnInit {

  page = {
    id_page_type: 4,
    name: null,
    banner_title: null,
    banner_image: null,
    content_title: null,
    content_subtitle: null,
    content_description: null,
    content_image: null
  }

  constructor(
    private _ap: AppService,
    public _ss: SharingService) {
      this._ss.getLocalStorage(); 
      this._ss.setTitle('Quiénes Somos');
    }  
 

  ngOnInit(): void {
    this.getPageInfo()    
  }

  getPageInfo(){
    this._ap.getInfoPageDetails(this.page.id_page_type).subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this.page = response.data[0];
        console.log(this.page);
      }else{
        console.log(response.msg);
      }
    });
  }

}
