<div class="container" *ngIf="promotion">
    <div class="promotion-header">
        <div class="promotion-img" [ngStyle]="{'background-image':'url(' + _ss.imgURL + promotion.banner_image + ')'}"></div>
        <div class="overlay">
            <div class="ribbon"><span>{{promotion.title}}</span></div>
        </div>
    </div>


    <div class="promotion-body">
        <div class="title">
            <h1>{{promotion.title}}</h1>
            <span _ngcontent-rik-c88="" class="line"></span>
        </div>
        <p [innerHTML]="promotion.description"></p>
    </div>

    <!-- slider de las promociones destacadas -->
    <div class="promotion-slider">
        <app-promotion-slider></app-promotion-slider>
    </div>

</div>