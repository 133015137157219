import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-vehicle-contact',
  templateUrl: './vehicle-contact.component.html',
  styleUrls: ['./vehicle-contact.component.scss']
})
export class VehicleContactComponent implements OnInit {
@Input() vehicle;

message = {
  message:"Estoy interesado en una cotización de este vehículo. Por favor, póngase en contacto conmigo",
  name:"",
  email:"",
  phone:""
}
workShopConfig;
discount:number;
percentage;
totalprice:number;
  constructor(
    private _ap: AppService
  ) { }

  ngOnInit(): void {
    this.totalprice = this.vehicle.precio;
    if(this.vehicle.precio_oferta){
      this.calcDiscount();
    }
  
  }

  calcDiscount(){
    this.discount = this.vehicle.precio - this.vehicle.precio_oferta;
    this.percentage = (this.vehicle.precio_oferta * 100)/this.vehicle.precio;
    this.percentage = 100 - this.percentage.toFixed(1);
    this.totalprice = this.vehicle.precio_oferta;
  }

  changeDiscount(event){
    if(event.target.checked){
      this.totalprice = Number(this.totalprice-this.discount);
    }else{
      this.totalprice = Number(this.totalprice)+Number(this.discount);
    }
    
  }

}
