<div *ngIf="banner.banner_image != null" class="banner" [ngStyle]="{'background-image':'url('+ _ss.imgURL + banner.banner_image + ')'}">
    <div class="overlay">
        <div class=banner_txt>
            <h1>{{banner.name}}</h1>
            <h2>{{banner.banner_title}}</h2>
        </div>
    </div>
</div>
<div class="banner" *ngIf="banner.banner_image == null  && _ss.acceskey != null">
    <div class="overlay-alert">
        <span class="alert">No ha configurado el banner de la página</span>
    </div>
</div>