import { Injectable } from "@angular/core";
import { Theme, themes, multisite } from "../../global/brands/theme";

@Injectable({
  providedIn: "root"
})
export class ThemeService {
  private active: Theme = multisite;
  private availableThemes: Theme[] = themes;

  getAvailableThemes(): Theme[] {
    return this.availableThemes;
  }

  getActiveTheme(): Theme {
    return this.active;
  }

  setBrandTheme(id){
    this.active = this.availableThemes.find(x => x.id == id);
    Object.keys(this.active.properties).forEach(property => {
      document.documentElement.style.setProperty(
        property,
        this.active.properties[property]
      );
    });
  }

  setActiveTheme(theme: Theme): void {
    this.active = theme;

    Object.keys(this.active.properties).forEach(property => {
      document.documentElement.style.setProperty(
        property,
        this.active.properties[property]
      );
    });
  }
}