import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { WORKSHOP_ICONS } from '../../constants';
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import { SharingService } from 'src/app/services/sharing.service';
import { VEHICLES } from '../../constants';

@Component({
  selector: 'app-workshop-vehicles',
  templateUrl: './workshop-vehicles.component.html',
  styleUrls: ['./workshop-vehicles.component.scss']
})
export class WorkshopVehiclesComponent implements OnInit {

  vehicles = [];
  banner = {};
  id = 0;
  fuel;
  engine;
  kilometers;
  calendar;
  bodywork;
  location;
  color;
  cardoor;
  faPlus = faPlus;
  icons = WORKSHOP_ICONS;
  page = {
    id_page_type: 1,
    name: null,
    banner_title: null,
    banner_image: null,
    content_title: null,
    content_subtitle: null,
    content_description: null
  };
  config = {
    itemsPerPage: 9,
    currentPage: 1,
    totalItems: this.vehicles.length
  };

  constructor(
    private _ap: AppService,
    public _ss: SharingService
    ) {
      this._ss.getLocalStorage();
      this._ss.checkSiteMaintenance();
      this._ss.setTitle('Vehículos');
    }

  ngOnInit(){
    this._ss.setLoadingAppointment(true);
    this.getInfoPage();
    this.fuel = this.icons.find(x => x.title === 'fuel').pathIcon;
    this.engine = this.icons.find(x => x.title === 'engine').pathIcon;
    this.kilometers = this.icons.find(x => x.title === 'speedometer').pathIcon;
    this.color = this.icons.find(x => x.title === 'color').pathIcon;
    this.cardoor = this.icons.find(x => x.title === 'car').pathIcon;
    this.calendar = this.icons.find(x => x.title === 'calendar').pathIcon;
    this.location = this.icons.find(x => x.title === 'location').pathIcon;
    this.bodywork = this.icons.find(x => x.title === 'car').pathIcon;
    this.getVehicles();
  }

  getInfoPage(){
    this._ap.getInfoPageDetails(this.page.id_page_type).subscribe((response) => {
      if (response.result === 'OK'){
        console.log(response.msg);
        this.page = response.data[0];
        console.log(this.page);
      }else{
        console.log(response.msg);
      }
    });
  }

  getVehicles(){
    this._ap.getActiveVehicles().subscribe((response)=>{
      if (response.result === 'OK'){
        console.log(response.msg);
        this.vehicles = response.data;
        console.log(this.vehicles);
      }else{
        console.log(response.msg);
        console.log('Se ha producido un error');
      }
      this._ss.setLoadingAppointment(false);
    });
  }

  pageChanged(event){
    this.config.currentPage = event;
  }

}
