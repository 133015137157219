import { Component, OnInit, HostListener } from '@angular/core';
import { faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { SharingService } from 'src/app/services/sharing.service';
import { AppService } from 'src/app/services/app.service';
import { PAGE_TYPES_IDS } from '../../../constants';
import { ThrowStmt } from '@angular/compiler';
 @Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  faPhone = faPhone;
  faEnvelope = faEnvelope;
  nav: any[];
  navFixed:boolean = false;
  pageType = PAGE_TYPES_IDS;
  constructor(
    public _ss: SharingService,
    private _ap: AppService
  ) {
  
   }

   @HostListener("window:scroll", [])
  onWindowScroll() {
      if (window.pageYOffset > 250 || document.body.scrollTop > 250) {
          this.navFixed = true;
      } 
     else if (this.navFixed && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 250) {
          this.navFixed = false;
      }
  }

  ngOnInit(){
    console.log("EL navegador es:"+ this._ss.navMenu);
    console.log(this._ss.workShopConfig.phone);
  }
}
