<app-banner-model-page [page]="page.id_page_type"></app-banner-model-page>
<div class="container" *ngIf="promotions.length > 0">
    <div class="promotions" *ngFor="let promotion of promotions | paginate: config">
        <a *ngIf="promotion.id_workshop == null" [routerLink]="['/promociones-marca', promotion.id]">
            <div class="promotion-container">
                <div class="promotions-img" [ngStyle]="{'background-image':'url(' + _ss.imgURL + promotion.image + ')'}"></div>
                <div class="overlay">
                    <div class="ribbon"><span>Promoción</span></div>
                </div>
                <div class="overlay-cover">
                    <span class="icon">
                        <fa-icon [icon]="faPlus"></fa-icon>
                    </span>
                </div>
            </div>
            <div class="promotion-body">
                <h1>{{promotion.title}}</h1>
                <p [innerHTML]="promotion.description"></p>
            </div>
        </a>
        <a *ngIf="promotion.id_workshop != null" [routerLink]="['/promociones', promotion.id]">
            <div class="promotion-container">
                <div class="promotions-img" [ngStyle]="{'background-image':'url(' + _ss.imgURL + promotion.image + ')'}"></div>
                <div class="overlay">
                    <div class="ribbon"><span>Promoción</span></div>
                </div>
                <div class="overlay-cover">
                    <span class="icon">
                        <fa-icon [icon]="faPlus"></fa-icon>
                    </span>
                </div>
            </div>
            <div class="promotion-body">
                <h1>{{promotion.title}}</h1>
                <p [innerHTML]="promotion.description"></p>
            </div>
        </a>
    </div>
</div>
<div class="container" *ngIf="promotions.length == 0 && _ss.acceskey != null">
    <div>
        <span class="alert">No tiene ningúna promocion activa</span>
    </div>
</div>
<div style="text-align:center" *ngIf="promotions.length > 0">
    <pagination-controls class="pagination" (pageChange)="pageChanged($event)" previousLabel="" nextLabel="">
    </pagination-controls>
</div>