<app-banner-model-page [page]="page.id_page_type"></app-banner-model-page>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-6 col-sm-4 col-lg-6 col-xl-6" id="banner-contact">
            <h1 *ngIf="page.content_title">{{page.content_title}}</h1>
            <h1 *ngIf="!page.content_title && _ss.acceskey != null">No ha configurado el titulo del contenido</h1>
            <h2 *ngIf="page.content_subtitle">{{page.content_subtitle}}</h2>
            <h2 *ngIf="!page.content_subtitle && _ss.acceskey != null">No ha configurado el subtitulo del contenido</h2>
            <span *ngIf="page.content_subtitle" class="line"></span>
            <div class="contact-buttons">
                <a id="phone" class="left" *ngIf="_ss.workShopConfig.phone" href="tel:{{_ss.workShopConfig.phone}}">LLAMAR AHORA</a>
                <a id="email" class="right" *ngIf="_ss.workShopConfig.email" href="mailto:{{_ss.workShopConfig.email}}">ESCRÍBENOS</a>
            </div>
            <div *ngIf="page.content_image" class="col-md-6 col-sm-4 col-lg-6 col-xl-6" id="image_contact" [ngStyle]="{'background-image':'url(' + _ss.imgURL + page.content_image + ')'}"></div>
        </div>
        <div class="col-md-6 col-sm-4 col-lg-6 col-xl-6" id="workshop-description">
            <p *ngIf="page.content_description" [innerHTML]="page.content_description"></p>
            <p *ngIf="!page.content_description && _ss.acceskey != null" class="alert">No ha configurado la descripción del contenido de la página</p>
        </div>
    </div>
</div>
&nbsp;
