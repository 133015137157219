<div class="banner_2" [ngStyle]="{'background-image':'url(' + infoBanner.image + ')'}">
    <div class="overlay">
        <div class=banner_2txt>
            <h1>{{infoBanner.title}}</h1>
            <h2>{{infoBanner.subtitle}}</h2>
            <div class="buttons">
                <div class="row">
                    <a class="btn btn-primary btn-lg" id="email" *ngIf="_ss.workShopConfig.email" href="mailto:{{_ss.workShopConfig.email}}">{{infoBanner.email.toUpperCase()}}</a>
                    <a class="btn btn-default btn-lg" id="phone" *ngIf="_ss.workShopConfig.phone" href="tel:{{_ss.workShopConfig.phone}}">{{infoBanner.phone.toUpperCase()}}</a>
                </div>
            </div>
        </div>
    </div>
    <app-workshop-appointment *ngIf="_ss.getModule(6)"></app-workshop-appointment>
</div>
