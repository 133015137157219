<div class="nav">
    <div class="top-bar">
        <ul>
            <li>
                <a *ngIf="_ss.workShopConfig.phone != ''" href="tel:+{{_ss.workShopConfig.phone}}">
                    <span>
                        <fa-icon [icon]="faPhone"></fa-icon>
                    </span>
                    {{_ss.workShopConfig.phone}}
                </a>
                <span *ngIf="_ss.workShopConfig.phone == '' && _ss.acceskey != null">
                    Teléfono sin configurar!
                </span>
            </li>
            <li>
                <a *ngIf="_ss.workShopConfig.email != ''" href="mailto:{{_ss.workShopConfig.email}}">
                    <span>
                        <fa-icon [icon]="faEnvelope"></fa-icon>
                    </span>
                    {{_ss.workShopConfig.email}}
                </a>
                <span *ngIf="_ss.workShopConfig.email == '' && _ss.acceskey != null">
                   Correo sin configurar!
                </span>
            </li>
        </ul>
    
    </div>
    <div class="header">
        <div class="logo">
            <a title="{{_ss.workShopConfig.site_title}}" href="/#/inicio">
                <div class="img"  [ngStyle]="{'background-image':'url(' + _ss.imgURL  + '/brands/' + _ss.workShopConfig.logo +')'}"></div>
                <span *ngIf="_ss.workShopConfig.site_title != null">{{_ss.workShopConfig.site_title}}</span>
                <span *ngIf="_ss.workShopConfig.site_title == '' && _ss.acceskey != null">
                    Nombre del sitio sin configurar!
                </span>
            </a>        
        </div>
        <app-nav></app-nav>
    </div>
</div>

<div class="nav fixed" [ngClass]="navFixed ? 'is-fixed' : ''">
    <div class="top-bar">
        <ul>
            <li>
                <a *ngIf="_ss.workShopConfig.phone != ''" href="tel:+{{_ss.workShopConfig.phone}}">
                    <span>
                        <fa-icon [icon]="faPhone"></fa-icon>
                    </span>
                    {{_ss.workShopConfig.phone}}
                </a>
                <span *ngIf="_ss.workShopConfig.phone == '' && _ss.acceskey != null">
                    Teléfono sin configurar!
                </span>
            </li>
            <li>
                <a *ngIf="_ss.workShopConfig.email != ''" href="mailto:{{_ss.workShopConfig.email}}">
                    <span>
                        <fa-icon [icon]="faEnvelope"></fa-icon>
                    </span>
                    {{_ss.workShopConfig.email}}
                </a>
                <span *ngIf="_ss.workShopConfig.email == '' && _ss.acceskey != null">
                   Correo sin configurar!
                </span>
            </li>
        </ul>
    
    </div>
    <div class="header">
        <div class="logo">
            <a title="{{_ss.workShopConfig.site_title}}" href="/#/inicio">
                <div class="img"  [ngStyle]="{'background-image':'url(' + _ss.imgURL  + '/brands/' + _ss.workShopConfig.logo +')'}"></div>
                <span *ngIf="_ss.workShopConfig.site_title != null">{{_ss.workShopConfig.site_title}}</span>
                <span *ngIf="_ss.workShopConfig.site_title == '' && _ss.acceskey != null">
                    Nombre del sitio sin configurar!
                </span>
            </a>        
        </div>
        <app-nav></app-nav>
    </div>
</div>