<app-header *ngIf="_ss.showHeader"></app-header>
<div class="body-w" [ngStyle]="{'position':_ss.appointmentStatus ? 'fixed' : ''}">
    <router-outlet></router-outlet>
    <div class="loading">
        <div class="loading-wrapper">
            <i class="fa-li fa fa-spinner fa-spin"></i>
            <div class="text"></div>
            <div class="subtext"></div>
        </div>
    </div>
</div>
<app-footer *ngIf="_ss.showFooter" [ngStyle]="{'display':_ss.appointmentStatus ? 'none' : ''}"></app-footer>
<a *ngIf="windowScrolled" class="scroll-to-top" (click)="scrollToTop()">
    <fa-icon [icon]="faChevronUp"></fa-icon>
</a>