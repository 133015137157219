import {Injectable} from '@angular/core';
import {URL} from '../config';
import {Meta, Title} from '@angular/platform-browser';
import Swal from 'sweetalert2';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SharingService {
  public vehicles = [];
  loadingStatus = false;
  loadingAppointmentStatus = false;

  qcarURL_image = URL.qcar_image_url;
  imgURL = URL.img_url;


  showFooter = true;
  showHeader = true;
  acceskey;

  private appointmentCenterId: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  public appointmentCenterId$: Observable<any[]> = this.appointmentCenterId.asObservable();
  appointmentStatus = false;

  dataSource;

  public workShopConfig = {
    id_workshop: null,
    id_qcar: null,
    id_brand: null,
    token: null,
    site_title: null,
    site_description: null,
    logo: null,
    phone: null,
    email: null,
    timetable: null,
    address: null,
    key: null,
    status: false
  };

  navMenu;

  public workShopModules = [
    {
      id: 0,
      name: 'home',
      title: 'Inicio',
      url: '/inicio',
      status: false
    },
    {
      id: 1,
      name: 'vehicles',
      title: 'vehiculos',
      url: 'vehiculos',
      status: false
    },
    {
      id: 2,
      name: 'services',
      title: 'servicios',
      url: 'servicios',
      status: false
    },
    {
      id: 3,
      name: 'promotions',
      title: 'promociones',
      url: 'promociones',
      status: false
    },
    {
      id: 4,
      name: 'whoweare',
      title: 'quienes somos',
      url: 'quienes-somos',
      status: false
    },
    {
      id: 5,
      name: 'contact',
      title: 'contacto',
      url: 'contacto',
      status: false
    },
    {
      id: 6,
      name: 'reservations',
      title: 'reservas',
      url: 'reservas',
      status: false
    },
    {
      id: 7,
      name: 'insurances',
      title: 'aseguradoras',
      url: 'seguros',
      status: false,
    },
    {
      id: 8,
      name: 'terms',
      title: 'Términos y condiciones',
      url: 'terminos-y-condiciones',
      status: false,
    },
    {
      id: 9,
      name: 'polices',
      title: 'Política de privacidad',
      url: 'politica-de-privacidad',
      status: false,
    },
    {
      id: 10,
      name: 'legal advice',
      title: 'Aviso legal',
      url: 'aviso-legal',
      status: false,
    }];

  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router
  ) {
  }

  setCenterId(id) {
    this.appointmentCenterId.next(id);
  }

  setLoading(status) {
    this.loadingStatus = status;
  }

  setLoadingAppointment(status) {
    this.loadingAppointmentStatus = status;
  }

  getWorkshopSettings() {
    return this.workShopConfig;
  }

  getModule(id) {
    return this.workShopModules.find(x => x.id === id).status;
  }

  // Set Title
  setTitle(newTitle: string) {
    this.titleService.setTitle(this.workShopConfig.site_title + ' | ' + newTitle);
  }

  isLoading() {
    return this.loadingStatus;
  }

  isLoadingAppointment() {
    return this.loadingAppointmentStatus;
  }

  setLocalStorage(dataSource) {
    localStorage.setItem('dataSource', dataSource);
  }

  getLocalStorage() {
    const data = localStorage.getItem('dataSource');
    this.workShopConfig = JSON.parse(data);
  }

  checkSiteMaintenance() {

    if (!this.workShopConfig.status) {
      if (this.acceskey != null && this.getWorkshopSettings().key === this.acceskey) {
        this.showFooter = true;
        this.showHeader = true;
      } else {
        this.showFooter = false;
        this.showHeader = false;
        this.router.navigateByUrl('/404');
      }
    }

  }

  handleError(error, errorFront, errorBody?) {
    console.log(error);
    this.setLoadingAppointment(false);
    Swal.fire({
      icon: 'error',
      title: 'Oops!',
      text: errorFront,
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
      timer: 2500
    });
  }

}
