
<!-- Si tiene colaboradores -->
<div id="insuraces-container" *ngIf="partners.length > 0">
    <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let partner of partners">
            <div class="insurance-image" [ngStyle]="{'background-image':'url(' + _ss.imgURL +'/partners/'+ partner.image + ')'}"></div>
        </ng-template>
    </owl-carousel-o>
</div>

<!-- Si no tiene colaboradores y está en modo mantenimiento -->
<div id="insuraces-container" class="no-slider"  *ngIf="_ss.acceskey != null && partners.length == 0">
    <div class="insurance-alert">
        <span class="alert">Actualmente no tiene ningún colaborador activo!</span>
    </div>
</div>
