import { Component, OnInit, Input } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';


@Component({
  selector: 'app-banner-model-one',
  templateUrl: './banner-model-one.component.html',
  styleUrls: ['./banner-model-one.component.scss']
})
export class BannerModelOneComponent implements OnInit {

    
  @Input() banner; 
  infoBanner;
  

  constructor(
    public _ss:SharingService
  ) {
   }



  ngOnInit(): void {
    this.infoBanner = this.banner;
    console.log(this.infoBanner);
    
  }

}
