import {Component, OnInit, NgZone, ViewChild, ElementRef, Input, OnDestroy, AfterViewInit} from '@angular/core';
import {AppService} from 'src/app/services/app.service';
import {SharingService} from 'src/app/services/sharing.service';
import {WORKSHOP_ICONS} from '../../constants';
import {faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-workshop-appointment',
  templateUrl: './workshop-appointment.component.html',
  styleUrls: ['./workshop-appointment.component.scss']
})
export class WorkshopAppointmentComponent implements OnInit, OnDestroy, AfterViewInit {
  mapOptions: google.maps.MapOptions = {
    center: {lat: -34.397, lng: 150.644},
    zoom: 8,
  };
  // Plate
  plate;

  // Calendar Nav Arrows
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  // Apointment status
  appointmentStatus = false;

  // All Icons
  icons = WORKSHOP_ICONS;

  // Icons
  revision;
  braking;
  tyre;
  distribution;
  // tslint:disable-next-line:variable-name
  air_conditioning;
  shocks;
  start;
  mechanic;
  bodywork;
  plus;

  // Date Calendar
  actualday;
  firstday;
  lastday;
  timeshift = 30;

  // Workshop Info
  workshop;

  // TecDoc
  brands = [];
  models = [];
  versions = [];
  lastkeydown = 0;

  // Centers
  centers = [];

  // Disable inputs on autodoc complete
  // tslint:disable-next-line:variable-name
  is_blocked = false;

  // Reservation data
  appointment = {
    centerId: null,
    centerName: null,
    centerEmail: null,
    centerPhone: null,
    vehicleData: {
      id: null,
      plate: null,
      id_brand: null,
      brand: null,
      id_version: null,
      version: null,
      id_model: null,
      model: null
    },


    services: [{
      name: 'revision',
      title: 'Revisión',
      status: false
    },
      {
        name: 'braking',
        title: 'Frenado',
        status: false
      },
      {
        name: 'tyre',
        title: 'Neumáticos',
        status: false
      },
      {
        name: 'distribution',
        title: 'Distribución',
        status: false
      },
      {
        name: 'air_conditioning',
        title: 'Climatización',
        status: false
      },
      {
        name: 'shocks',
        title: 'Amorgituadores',
        status: false
      },
      {
        name: 'start',
        title: 'Arranque',
        status: false
      },
      {
        name: 'itv',
        title: 'Pre-ITV',
        status: false
      },
      {
        name: 'bodywork',
        title: 'Carrocería',
        status: false,
      },
      {
        name: 'other',
        title: 'Otro',
        status: false,
      }],

    contactData: {
      name: '',
      surname: '',
      secondSurname: '',
      phone: '',
      email: '',
    },
    remarks: 'o descríbenos lo que deseés',
    bookingDate: {
      day: '',
      timestamp: '',
      hour: '',
    },
    pickupData: {
      needsTow: false,
      streetName: '',
      number: '',
      council: '',
      province: '',
      postalCode: '',
      latitude: 0,
      longitude: 0
    },
    pickup: false,
    courtesy_vehicle: false,
  };

  submitted = false;
  registerForm: FormGroup;

  showCenters = true;

  // Calendar
  hourscalendar;
  calendar = [];

  // Maps
  title: string;
  draggable = false;
  iconUrl: string;
  visible = true;
  openInfoWindow = true;
  opacity = 1;
  zIndex = 1;
  clickable = true;
  zoom = 6;
  private geoCoder;
  autocompleteAddress;

  @ViewChild('autoCompleteInput') autoCompleteInput: ElementRef;


  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(
    // tslint:disable-next-line:variable-name
    private _ap: AppService,
    // tslint:disable-next-line:variable-name
    public _ss: SharingService,
    public ngZone: NgZone,
    private formBuilder: FormBuilder
  ) {
  }

  ngAfterViewInit() {
    this.initializeAutocomplete();
  }

  ngOnInit(): void {
    this._ss.appointmentCenterId$.subscribe(appointmentCenterId => this.getCenterCalendar(appointmentCenterId));

    this.registerForm = this.formBuilder.group({
      plate: ['', Validators.required],
      brand: ['', Validators.required],
      model: ['', Validators.required],
      version: ['', Validators.required],
      name: ['', Validators.required],
      firstsurname: ['', Validators.required],
      secondsurname: [''],
      phone: ['', Validators.required],
      center: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      appointment: ['', Validators.required],
      policy: [true, Validators.requiredTrue],
      streetName: [''],
      number: [''],
      province: [''],
      council: [''],
      postalCode: ['']
    });
    this.getBrands();
    moment.locale('es');
    this.revision = this.icons.find(x => x.title === 'car_five').pathIcon;
    this.braking = this.icons.find(x => x.title === 'braking').pathIcon;
    this.tyre = this.icons.find(x => x.title === 'tyre').pathIcon;
    this.distribution = this.icons.find(x => x.title === 'distribution').pathIcon;
    this.air_conditioning = this.icons.find(x => x.title === 'air').pathIcon;
    this.shocks = this.icons.find(x => x.title === 'shocks').pathIcon;
    this.start = this.icons.find(x => x.title === 'battery').pathIcon;
    this.mechanic = this.icons.find(x => x.title === 'mechanic').pathIcon;
    this.bodywork = this.icons.find(x => x.title === 'car').pathIcon;
    this.plus = this.icons.find(x => x.title === 'plus').pathIcon;

    this.getCenters();
    this.actualday = moment().format('YYYY-MM-DD');
    this.firstday = moment().startOf('week').format('YYYY-MM-DD');
    this.lastday = moment().endOf('week').format('YYYY-MM-DD');
    if (this.actualday === this.lastday) {
      this.firstday = moment(this.lastday, 'YYYY-MM-DD').add(1, 'day');
      this.lastday = moment(this.lastday, 'YYYY-MM-DD').add(7, 'days');
    }

    console.log(this.workshop);
  }

  initializeAutocomplete(): void {
    if (this.autoCompleteInput && this.autoCompleteInput.nativeElement) {
      this.geoCoder = new google.maps.Geocoder();
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);

      autocomplete.addListener('place_changed', () => {
        console.log('autocompletando');
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (!place.geometry) {
            return;
          }

          const latitude = place.geometry.location.lat();
          const longitude = place.geometry.location.lng();
          this.zoom = 12;
          const address = place.address_components;

          this.appointment.pickupData.latitude = latitude;
          this.appointment.pickupData.longitude = longitude;
          console.log(latitude);
          console.log(longitude);
          console.log(place);
          console.log(this.appointment.pickupData);

          this.getgoogleAddress(address);
        });
      });
    }

  }

  get f() {
    return this.registerForm.controls;
  }

  public changeBrand(e): void {
    const name = e.target.value;
    this.appointment.vehicleData.id_brand = null;
    this.appointment.vehicleData.brand = name;
    this.clearModel();
    if (this.brands.length > 0) {
      console.log(this.brands);
      console.log(this.brands.filter(x => x.name === name)[0]);
      if (this.brands.filter(x => x.name === name)[0]) {
        const list = this.brands.filter(x => x.name === name)[0];
        this.appointment.vehicleData.id_brand = list.id;
        console.log(this.appointment);
        if (list.id) {
          this.getModels(list.id);
        }
      }
    }

  }

  getCenterCalendar(id) {
    console.log('Cargando calendario del centro: ' + id);
    this.appointment.centerId = id;
    if (this.appointment.centerId) {
      this.showCenters = false;
      this.getCalendarWeek();
    }
  }


  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.appointment.pickupData.latitude = position.coords.latitude;
        this.appointment.pickupData.longitude = position.coords.longitude;
        this.zoom = 12;
      });
    }
  }

  getgoogleAddress(address: any) {
    console.log(address.length);
    switch (address.length) {
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        this.appointment.pickupData.number = '';
        this.appointment.pickupData.streetName = '';
        this.appointment.pickupData.council = address[0].long_name;
        this.appointment.pickupData.province = address[1].long_name;
        this.appointment.pickupData.postalCode = '';
        this.registerForm.controls.council.setValue(address[0].long_name);
        this.registerForm.controls.province.setValue(address[1].long_name);
        break;

      case 5:
        if (address[0].types[0] === 'route') {
          console.log('entre aqui');
          this.appointment.pickupData.number = '';
          this.appointment.pickupData.postalCode = '';
          this.appointment.pickupData.streetName = address[0].long_name;
          this.appointment.pickupData.council = address[1].long_name;
          this.appointment.pickupData.province = address[2].long_name;
          this.appointment.pickupData.council = address[4].long_name;
          this.registerForm.controls.number.setValue('');
          this.registerForm.controls.streetName.setValue(address[0].long_name);
          this.registerForm.controls.postalCode.setValue('');
          this.registerForm.controls.council.setValue(address[1].long_name);
          this.registerForm.controls.province.setValue(address[2].long_name);
        } else {
          this.appointment.pickupData.number = '';
          this.appointment.pickupData.postalCode = address[0].long_name;
          this.appointment.pickupData.streetName = address[0].long_name;
          this.appointment.pickupData.council = address[1].long_name;
          this.appointment.pickupData.province = address[2].long_name;
          this.registerForm.controls.number.setValue('');
          this.registerForm.controls.streetName.setValue('');
          this.registerForm.controls.postalCode.setValue(address[0].long_name);
          this.registerForm.controls.council.setValue(address[1].long_name);
          this.registerForm.controls.province.setValue(address[2].long_name);

        }
        break;

      case 6:
        if (address[0].types[0] === 'street_number') {
          this.appointment.pickupData.number = address[0].long_name;
          this.appointment.pickupData.streetName = address[1].long_name;
          this.appointment.pickupData.council = address[2].long_name;
          this.appointment.pickupData.province = address[2].long_name;
          // this.appointment.pickupData.community = address[3].long_name;
          this.appointment.pickupData.council = address[4].long_name;
          this.appointment.pickupData.postalCode = address[5].long_name;
          this.registerForm.controls.number.setValue(address[0].long_name);
          this.registerForm.controls.streetName.setValue(address[1].long_name);
          this.registerForm.controls.council.setValue(address[2].long_name);
          this.registerForm.controls.province.setValue(address[2].long_name);
          this.registerForm.controls.postalCode.setValue(address[5].long_name);
        } else {
          this.appointment.pickupData.number = '';
          this.appointment.pickupData.streetName = address[0].long_name;
          this.appointment.pickupData.council = address[1].long_name;
          this.appointment.pickupData.province = address[2].long_name;
          this.registerForm.controls.streetName.setValue(address[0].long_name);
          this.registerForm.controls.council.setValue(address[1].long_name);
          this.registerForm.controls.province.setValue(address[2].long_name);
        }
        break;
      default:
        this.appointment.pickupData.number = address[0].long_name;
        this.appointment.pickupData.streetName = address[1].long_name;
        this.appointment.pickupData.council = address[2].long_name;
        this.appointment.pickupData.province = address[3].long_name;
        this.appointment.pickupData.postalCode = address[6].long_name;
        this.registerForm.controls.number.setValue(address[0].long_name);
        this.registerForm.controls.streetName.setValue(address[1].long_name);
        this.registerForm.controls.council.setValue(address[2].long_name);
        this.registerForm.controls.province.setValue(address[3].long_name);
        this.registerForm.controls.postalCode.setValue(address[6].long_name);
    }

    console.log(address);
    console.log(this.appointment.pickupData);
  }

  markerDragEnd($event: google.maps.MouseEvent) {
    const coords = $event.latLng;
    if (coords) {
      const latitude = coords.lat();
      const longitude = coords.lng();
      this.getAddress(latitude, longitude);
      this.appointment.pickupData.latitude = latitude;
      this.appointment.pickupData.longitude = longitude;
    } else {
      console.error('Coordinates are not available in the event.');
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({location: {lat: latitude, lng: longitude}}, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          console.log('cargando address');
          this.zoom = 12;
          const address = results[0].address_components;
          this.getgoogleAddress(address);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  public changeModel(e): void {
    const name = e.target.value;
    this.appointment.vehicleData.id_model = null;
    this.appointment.vehicleData.model = name;
    this.clearVersion();
    console.log(this.models);
    if (this.models.length > 0) {
      console.log(this.models.filter(x => x.name === name)[0]);
      if (this.models.filter(x => x.name === name)[0]) {
        const list = this.models.filter(x => x.name === name)[0];
        this.appointment.vehicleData.id_model = list.id;
        if (list.id) {
          this.getVersions(this.appointment.vehicleData.id_brand, list.id);
        }
      }
    }
  }


  public changeVersion(e): void {
    const name = e.target.value;
    this.appointment.vehicleData.id_version = null;
    this.appointment.vehicleData.version = name;
    if (this.versions.length > 0) {
      if (this.versions.filter(x => x.name === name)[0]) {
        const list = this.versions.filter(x => x.name === name)[0];
        this.appointment.vehicleData.id_version = list.id;
      }
    }
  }

  changeCenter($event: Event) {
    const target = event.target as HTMLSelectElement;
    const id = target.value;
    this.appointment.centerId = id;
    this.appointment.centerName = this.centers.find(x => x.id_driver360_center == id).name;
    this.appointment.centerEmail = this.centers.find(x => x.id_driver360_center == id).email;
    this.appointment.centerPhone = this.centers.find(x => x.id_driver360_center == id).phone;
    console.log(this.appointment);
    this.getCalendarWeek();
  }

  getCenters() {
    this._ap.getWorkshopCenters().subscribe((response) => {
      if (response.result === 'OK') {
        console.log(response.msg);
        console.log('centrooooosss');
        console.log(response.data);
        this.centers = response.data;
        this.appointment.centerId = response.data[0].id_driver360_center;
      } else {
        console.log(response.msg);
        console.log('Se ha producido un error');
      }
    });
  }

  getPlate() {
    this.appointment.vehicleData.plate = this.registerForm.controls.plate.value;
  }

  setProvince() {
    this.appointment.pickupData.province = this.registerForm.controls.province.value;
  }

  setPostalCode() {
    this.appointment.pickupData.postalCode = this.registerForm.controls.postalCode.value;
  }

  setCouncil() {
    this.appointment.pickupData.council = this.registerForm.controls.council.value;
  }

  setNumber() {
    this.appointment.pickupData.number = this.registerForm.controls.number.value;
  }

  getName() {
    this.appointment.contactData.name = this.registerForm.controls.name.value;
  }

  getSurname() {
    this.appointment.contactData.surname = this.registerForm.controls.firstsurname.value;
  }

  getSecondSurname() {
    this.appointment.contactData.secondSurname = this.registerForm.controls.secondsurname.value;
  }

  getPhone() {
    this.appointment.contactData.phone = this.registerForm.controls.phone.value;
  }

  getEmail() {
    this.appointment.contactData.email = this.registerForm.controls.email.value;
  }


  checkPlate() {
    console.log(this.brands);
    console.log('Buscando matrícula en el sistema....');
    this.appointment.vehicleData.id_brand = null;
    this.appointment.vehicleData.brand = null;
    this.appointment.vehicleData.id_model = null;
    this.appointment.vehicleData.model = null;
    this.appointment.vehicleData.version = null;
    this.appointment.vehicleData.id_version = null;

    this.appointment.vehicleData.plate = this.registerForm.controls.plate.value;
    console.log(this.appointment.vehicleData.plate);
    if (this.appointment.vehicleData.plate !== '') {
      this._ap.checkWorkShopPlate(this.appointment.vehicleData.plate).subscribe((response) => {
        if (response.result === 'OK') {
          console.log(response);
          this.appointment.vehicleData.id = response.data.vehicleId;
          this.appointment.vehicleData.brand = response.data.vehicleBrand;
          this.appointment.vehicleData.model = response.data.vehicleModel;
          this.appointment.vehicleData.version = response.data.vehicleVersion;
          this.registerForm.controls.brand.setValue(response.data.vehicleBrand);
          this.registerForm.controls.model.setValue(response.data.vehicleModel);
          this.registerForm.controls.version.setValue(response.data.vehicleVersion);
          this.is_blocked = true;
          this.registerForm.controls.brand.disable();
          this.registerForm.controls.model.disable();
          this.registerForm.controls.version.disable();

          console.log(this.is_blocked);
        } else {
          console.log('Se ha producido un error');
        }
      });
    }
    this.appointmentStatus = true;
    this._ss.appointmentStatus = true;
  }

  enableVehicleData() {
    this.registerForm.controls.brand.enable();
    this.registerForm.controls.model.enable();
    this.registerForm.controls.version.enable();
  }

  getBrands() {
    this._ap.getTecdocBrands().subscribe((response) => {
      if (response.result === 'OK') {
        this.brands = response.data;
        console.log(this.brands);
        console.log(response.msg);
      } else {
        console.log('Se ha producido un error');
        console.log(response.msg);
      }
    });
  }

  getModels(id) {
    this._ap.getTecdocModels(id).subscribe((response) => {
      if (response.result === 'OK') {
        this.models = response.data;
        console.log(this.models);
        console.log(response.msg);
      } else {
        console.log('Se ha producido un error');
        console.log(response.msg);
      }
    });
  }

  getVersions(idBrand, idModel) {
    this._ap.getTecdocVersions(idBrand, idModel).subscribe((response) => {
      if (response.result === 'OK') {
        this.versions = response.data;
        console.log(this.versions);
        console.log(response.msg);
      } else {
        console.log('Se ha producido un error');
        console.log(response.msg);
      }
    });
  }

  getCalendarWeek() {
    this._ss.setLoadingAppointment(true);
    console.log('calendario dias');
    console.log(this.firstday);
    console.log(this.lastday);
    console.log(this.calendar);
    this._ap.getWorkShopCalendar(this.appointment.centerId, this.firstday, this.timeshift).subscribe((response) => {
      if (response.result === 'OK') {
        console.log(response.msg);
        this.calendar = response.data;
        console.log(this.calendar);
      } else {
        console.log('Se ha producido un error');
        console.log(response.msg);
      }
      this._ss.setLoadingAppointment(false);
    }, (error) => {
      this._ss.handleError(error, error.msg || 'Servicio no disponible');
    });
  }

  getServiceStatus(name) {
    return this.appointment.services.find(x => x.name === name).status;
  }

  setServiceStatus(name) {
    this.appointment.services.find(x => x.name === name).status = !this.appointment.services.find(x => x.name === name).status;
  }

  clearData() {
    // Borramos la matricula
    this.plate = null;

    this.versions = null;
    this.models = null;

    // Borramos datos de vehiculo
    this.appointment.vehicleData = {
      id: null,
      plate: null,
      id_brand: null,
      brand: null,
      id_version: null,
      version: null,
      id_model: null,
      model: null
    };

    // Borramos datos de contacto
    this.appointment.contactData = {
      name: '',
      surname: '',
      secondSurname: '',
      phone: '',
      email: ''
    };

    // Borramos servicios seleccionados
    this.appointment.services.forEach(service => {
      service.status = false;
    });

    // Borrar datos calendario
    this.appointment.bookingDate.day = null;
    this.appointment.bookingDate.hour = null;
    this.appointment.bookingDate.timestamp = null;

    // Borrar servicios adicionales o remarks
    this.appointment.remarks = null;

    // Borrar datos de vehiculos adicionales
    this.appointment.courtesy_vehicle = false;
    this.appointment.pickupData.needsTow = false;
    this.clearAppointment();

    // Desbloquear inputs
    this.is_blocked = false;

    // Limpiar Calendario
    this.calendar = [];

    // PickUp Data

    this.appointment.pickupData.needsTow = false;
    this.appointment.pickupData.streetName = '';
    this.appointment.pickupData.number = '';
    this.appointment.pickupData.council = '';
    this.appointment.pickupData.province = '';
    this.appointment.pickupData.postalCode = '';
    this.appointment.pickupData.latitude = 0;
    this.appointment.pickupData.longitude = 0;

    this.appointment.pickup = false;

    // Restear Formulario

    this.submitted = false;
    this.registerForm.reset();
    console.log(this.registerForm.errors);
    console.log(this.submitted);
    this.registerForm.controls.center.setValue('');
    this.enableVehicleData();
  }

  setAppointment(hourSelected, daySelected, timestamp) {
    console.log('hecho click aqui');
    console.log('la reserva es para :' + daySelected + ' a las ' + hourSelected + 'el timestamp' + timestamp);
    this.appointment.bookingDate.day = daySelected;
    this.appointment.bookingDate.hour = hourSelected;
    this.appointment.bookingDate.timestamp = moment(timestamp).format('YYYY-MM-DD HH:mm');
    this.registerForm.controls.appointment.setValue(this.appointment.bookingDate.timestamp);
    this.clearAppointment();
  }

  clearAppointment() {
    console.log('limpiar calendario');
    this.calendar.forEach(day => {
      day.hours_remaining.forEach(hour => {
        hour.appointment = false;
      });
    });
  }

  setPickup(e) {
    this.setCurrentPosition();
    console.log('checkbox recogida');
    this.appointment.pickup = e.target.checked;
  }

  setCrane(e) {
    console.log('checkbox grua');
    this.appointment.pickupData.needsTow = e.target.checked;
  }

  setCourtesyVehicle(e) {
    console.log('checkbox vehiculo de cortesía');
    this.appointment.courtesy_vehicle = e.target.checked;
  }

  closeAppointment() {
    this.appointmentStatus = false;
    this._ss.appointmentStatus = false;
    this.clearData();
  }

  confirmAppointment() {
    this._ss.setLoadingAppointment(true);
    console.log('enviando cita de taller....');
    this._ap.confirmWorkShopAppointment(this.appointment).subscribe((response) => {
      console.log(response);
      this._ss.setLoadingAppointment(false);
      if (response.result === 'OK') {
        Swal.fire({
          icon: 'success',
          title: 'Gracias ' + this.appointment.contactData.name + '!',
          text: 'En breves recibirá un mensaje a su correo electrónico o teléfono con los detalles de la cita',
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2500
        });
        this.closeAppointment();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Se ha producido un error!',
          text: response.msg,
          showCloseButton: false,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2500
        });
      }
    });
  }

  changeCalendarNextWeek() {
    this.firstday = moment(this.firstday).add(1, 'week').format('YYYY-MM-DD');
    this.lastday = moment(this.lastday).add(1, 'week').format('YYYY-MM-DD');
    this.clearBookingDate();
    this.getCalendarWeek();
  }

  changeCalendarPrevWeek() {
    this.firstday = moment(this.firstday).subtract(1, 'week').format('YYYY-MM-DD');
    this.lastday = moment(this.lastday).subtract(1, 'week').format('YYYY-MM-DD');
    this.clearBookingDate();
    this.getCalendarWeek();
  }

  clearBookingDate() {
    this.appointment.bookingDate.day = null;
    this.appointment.bookingDate.timestamp = null;
    this.appointment.bookingDate.hour = null;
  }

  checkCanNotBooking(hour, timeline, day) {
    const flag = false;
    const h = hour.hour.split(':')[0];
    // tslint:disable-next-line:max-line-length
    if (hour.hour === timeline.hour && !hour.available || day.timestamp < this.actualday || day.timestamp === this.actualday && hour.hour === timeline.hour) {
      return true;
    }
  }

  getActualWeek() {
    this._ss.setLoadingAppointment(true);
    this.firstday = moment().startOf('week').format('YYYY-MM-DD');
    this.lastday = moment().endOf('week').format('YYYY-MM-DD');
    this.getCalendarWeek();
  }

  clearPlate() {
    this.registerForm.controls.plate.setValue('');
    this.appointment.vehicleData.plate = null;
  }

  clearVehicleData() {
    this.appointment.vehicleData.id = null;
    this.appointment.vehicleData.plate = null;
    this.clearPlate();
    this.clearBrand();
    this.enableVehicleData();
    this.is_blocked = false;
  }

  clearBrand() {
    this.models = null;
    this.appointment.vehicleData.id_brand = null;
    this.appointment.vehicleData.brand = null;
    this.registerForm.controls.brand.setValue('');
    this.clearModel();
  }

  clearModel() {
    this.versions = null;
    this.appointment.vehicleData.id_model = null;
    this.appointment.vehicleData.model = null;
    this.registerForm.controls.model.setValue('');
    this.clearVersion();
  }

  clearVersion() {
    this.appointment.vehicleData.id_version = null;
    this.appointment.vehicleData.version = null;
    this.registerForm.controls.version.setValue('');
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: 'Algunos de los campos son obligatorios',
        showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
        timer: 2500
      });
      return;
    }

    // console.log(this.appointment);
    // console.log('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));
    this.confirmAppointment();
  }


  changeTimelineCalendar(status) {
    const yOffset = 0;
    const element = document.getElementById(status);
    const div = document.getElementById('calendar');
    const y = element.offsetTop + yOffset;
    div.scrollTo({top: y, behavior: 'smooth'});
  }

  ngOnDestroy() {
    this._ss.setCenterId(null);
  }

}

