<div id="promotion-container" *ngIf="promotions.length > 0">
    <h1>{{title}}</h1>
    <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let promotion of promotions">
            <div class="promotions">
                <a *ngIf="promotion.id_brand" [routerLink]="['/promociones-marca', promotion.id]">
                    <div class="promotion-container">
                        <div class="promotions-img" [ngStyle]="{'background-image':'url('+ _ss.imgURL + promotion.image + ')'}"></div>
                        <div class="overlay">
                            <div class="ribbon"><span>Promoción</span></div>
                        </div>
                        <div class="overlay-cover">
                            <span class="icon">
                                <fa-icon [icon]="faPlus"></fa-icon>
                            </span>
                        </div>
                    </div>
                    <div class="promotion-body">
                        <h1>{{promotion.title}}</h1>
                        <p [innerHTML]="promotion.description"></p>
                    </div>
                </a>
                <a *ngIf="promotion.id_workshop" [routerLink]="['/promociones', promotion.id]">
                    <div class="promotion-container">
                        <div class="promotions-img" [ngStyle]="{'background-image':'url('+ _ss.imgURL + promotion.image + ')'}"></div>
                        <div class="overlay">
                            <div class="ribbon"><span>Promoción</span></div>
                        </div>
                        <div class="overlay-cover">
                            <span class="icon">
                                <fa-icon [icon]="faPlus"></fa-icon>
                            </span>
                        </div>
                    </div>
                    <div class="promotion-body">
                        <h1>{{promotion.title}}</h1>
                        <p [innerHTML]="promotion.description"></p>
                    </div>
                </a>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>

<div id="promotion-container" *ngIf="_ss.acceskey != null && promotions.length == 0">
    <div class="promotion-alert">
        <span class="alert">Actualmente no tiene ninguna campaña activa!</span>
    </div>
</div>