import { Component, OnInit, Input } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-banner-model-page',
  templateUrl: './banner-model-page.component.html',
  styleUrls: ['./banner-model-page.component.scss']
})
export class BannerModelPageComponent implements OnInit {

  constructor(
    public _ss: SharingService,
    private _ap: AppService
  ) { }

  @Input() page;
  
  banner = {
    id: null,
    name: null,
    banner_title: null,
    banner_image: null
  };

  ngOnInit(): void {
    this.getBannerInfo();
  }

  getBannerInfo(){
    this._ap.getInfoPageDetails(this.page).subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        console.log("el baner es...");
        this.banner = response.data[0];
        console.log(this.banner);
      }else{
        console.log(response.msg);
        console.log("Se ha producido un error");
      }
    });
  }

}
