<google-map
  height="400px"
  width="100%"
  [center]="center"
  [zoom]="zoom"
  [options]="options"
  #map>

  <!-- Iterar sobre los centros y crear marcadores -->
  <map-marker *ngFor="let center of centers"
              [position]="{ lat: center.latitude, lng: center.longitude }"
              [label]="center.name"
              (mapClick)="showInfo(center)">

    <!-- Información del marker -->
    <map-info-window>
      <div class="contact-bar">
        <a class="btn-contact" href="tel:{{center.phone}}">
          <fa-icon [icon]="faPhone"></fa-icon>
        </a>
        <a class="btn-contact email" href="mailto:{{center.email}}">
          <fa-icon [icon]="faEnvelope"></fa-icon>
        </a>
      </div>
      <div class="info-content">
        <p *ngIf="center.description" [innerHTML]="center.description"></p>
        <p *ngIf="!center.description"><b>¡No hay descripción!</b></p>
        <div>
          <b>Dirección:</b>
          <div class="row">
            {{center.street}}, {{center.number}}<br />{{center.council}} {{center.province}}
          </div>
        </div>
      </div>
      <a class="btn-booking" (click)="showAppointmentCenter(center)">PEDIR CITA</a>
    </map-info-window>
  </map-marker>
</google-map>
