<div class="content">
    <app-workshop-appointment [ngStyle]="{'display':_ss.appointmentStatus ? 'flex' : 'none'}"></app-workshop-appointment>
    <app-banner-model-page [page]="page.id_page_type"></app-banner-model-page>
   
    <div class="contact-container">
        <div class="form-container">
            <div class="col-md-6 col-lg-6 col-sx-6 col-xs-6" id="banner-contact">
                <div id="image_contact">
                    <h3>Información contacto</h3>
                    <ul class="contact-info">
                        <li><b>{{_ss.getWorkshopSettings().site_title}}</b></li>
                        <li><span><i><fa-icon [icon]="faPhone"></fa-icon></i>{{_ss.getWorkshopSettings().phone}}</span></li>
                        <li><span><i><fa-icon [icon]="faEnvelope"></fa-icon></i>{{_ss.getWorkshopSettings().email}}</span></li>
                    </ul>   
                    <h3>{{page.content_title}}</h3>       
                    <p *ngIf="page.content_description" [innerHTML]="page.content_description"></p>
                    <p class="alert" *ngIf="!page.content_description && _ss.acceskey != null"> No ha configurado la descripción del contenido de la página</p>
                </div>
            </div>
            <div class="col-md-6 col-lg-6 col-sx-6 col-xs-6" id="form-contact">
                <form class="form-horizontal" [formGroup]="emailForm" (ngSubmit)="onSubmit()">
                    <fieldset>
                        <div class="row">
                            <div class="input-half">
                                <label class="control-label" for="name"></label>
                                <input id="name_form" name="name" formControlName="name" type="text" placeholder="NOMBRE*" class="form-control input-md" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" required>
                                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                    <div *ngIf="f.name.errors.required">* Campo obligatorio</div>
                                </div>
                            </div>
                                
                            <div class="input-half">
                                <label class="control-label" for="phone"></label>
                                <input id="phone_form" name="phone" formControlName="phone" type="number" placeholder="TELÉFONO" class="form-control input-md" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" required>
                                <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                    <div *ngIf="f.phone.errors.required">* Campo obligatorio</div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <label class="control-label" for="email"></label>
                            <select class="form-control" name="center" formControlName="center" [ngClass]="{ 'is-invalid': submitted && f.center.errors }" required>
                                <option value="" disabled selected>Seleccione un centro</option>
                                <option *ngFor="let center of centers" [value]="center.email">{{center.name}}</option>
                            </select>
                            <div *ngIf="submitted && f.center.errors" class="invalid-feedback">
                                <div *ngIf="f.center.errors.required">* Campo obligatorio</div>
                            </div>
                        </div>
    
                        <div>
                            <label class="control-label" for="email"></label>
                            <input id="email_form" name="email" formControlName="email" type="text" placeholder="EMAIL*" class="form-control input-md" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" required>
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">* Campo obligatorio</div>
                                <div *ngIf="f.email.errors.email">* Debe introducir un email correcto</div>
                            </div>
                        </div>
    
                        <div>
                            <label class="control-label" for="textarea_form"></label>
                            <textarea class="form-control" id="textarea_form" name="messager" formControlName="message">MENSAJE</textarea>
                        </div>
    
    
                        <button class="" id="send_form">Enviar mensaje</button>
                    </fieldset>
                </form>
    
            </div>
        </div>
    </div>

    <app-map *ngIf="centers.length > 0" [centers]="centers"></app-map>
</div>
