<app-banner-model-page [page]="page.id_page_type"></app-banner-model-page>
<div class="container" *ngIf="services.length > 0">
    <div class="service" *ngFor="let service of services | paginate: config">
        <div class="service-container">
            <div class="service-img" [inlineSVG]="_ss.imgURL +'/service/'+ service.image"></div>
            <div class="overlay"></div>
        </div>
        <div class="services-body">
            <div class="title">
                <h1>{{service.title}}</h1>
                <span class="line"></span>
            </div>
            <p [innerHTML]="service.description"></p>
        </div>
        <a class="button read-more" (click)="showDetailsOfService(service.id_service)">leer mas</a>
    </div>
</div>
<div class="container" *ngIf="services.length == 0 && _ss.acceskey != null">
    <div>
        <span class="alert">No tiene ningún servicio activo</span>
    </div>
</div>
<div style="text-align:center" *ngIf="services.length > 0">
    <pagination-controls class="pagination " (pageChange)="pageChanged($event)" previousLabel="" nextLabel=""></pagination-controls>
</div>

<div class="modal-container" [ngClass]="showService ? 'show' : ''">
    <div class="service" *ngIf="service">
        <div class="service-container">
          <div class="service-img" [inlineSVG]="_ss.imgURL +'/service/'+ service.image"></div>
            <div class="title">
                <h1>{{service.title}}</h1>
                <span class="line"></span>
            </div>
        </div>
        <div class="button close" (click)="closeModalService()">
            <div class="service-img" [inlineSVG]="close"></div>
        </div>
        <div class="service-details">
            <div class="services-body">
                <p [innerHTML]="service.description"></p>
            </div>
        </div>
    </div>
</div>
