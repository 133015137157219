import { Component, OnInit, AfterViewInit, Inject, HostListener } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { WORKSHOP_ICONS } from '../../constants';
import { InlineSVGConfig } from 'ng-inline-svg';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { DOCUMENT } from '@angular/common';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss']

})

export class VehicleDetailsComponent implements OnInit {

  customOptions: any = {
    autoplay: false,
    // autoplayTimeout: 3000,
    autoplayHoverPause: true,
    loop: true,
    animateOut: 'fadeOut',
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<div class=\'nav-btn prev-slide\'></div>', '<div class=\'nav-btn next-slide\'></div>'],
    items: 1,
    autoHeight: true,
    autoHeightClass: 'owl-height',
    nav: true
  };

  thumbOptions: any = {
    autoplay: false,
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [''],
    items: 5,
    autoHeight: true,
    autoHeightClass: 'owl-height',
    nav: true
  };

  thumbNavigation = false;

  vehicleId;

  // Vehicle OBJECT
  vehicle;
  images = [];
  equipments = [];
  extras = [];
  accesories = [];

  vehicles;

  // Icons
  fuel: string;
  engine: string;
  color: string;
  road: string;
  mechanic: string;
  cardoor;
  kilometers;
  dgt;
  motor;
  earshift;
  check;
  calendar;

  faChevronDown = faChevronDown;
  icons = WORKSHOP_ICONS;

  // Details Tabs
  activeTabEquipment = 'Confort';
  activeTabExtras = 'General';
  showEquipmentStatus = true;
  showExtraStatus = false;
  showAccesoriesStatus = false;

  // Price Tabs
  priceTabStatus = true;

  // Vehicle Shop Now
  shopNowScrolled = true;

  // Discounted Price
  discount;
  percentage;

  constructor(
    private activatedRoute: ActivatedRoute,
    // tslint:disable-next-line:variable-name
    private _ap: AppService,
    // tslint:disable-next-line:variable-name
    public _ss: SharingService,
  ) {

  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
      if (window.pageYOffset > 600 || document.body.scrollTop > 600) {
          this.shopNowScrolled = false;
      }
     else if (this.shopNowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
          this.shopNowScrolled = true;
      }
  }

  ngOnInit(){

    this.activatedRoute.params.subscribe(params => {
      this.vehicleId = params.id;
      this.getVehicleDetails();
    });

    this.fuel = this.icons.find(x => x.title === 'fuel').pathIcon;
    this.engine = this.icons.find(x => x.title === 'engine').pathIcon;
    this.kilometers = this.icons.find(x => x.title === 'speedometer').pathIcon;
    this.color = this.icons.find(x => x.title === 'color').pathIcon;
    this.cardoor = this.icons.find(x => x.title === 'car').pathIcon;
    this.dgt = this.icons.find(x => x.title === 'dgt').pathIcon;
    this.earshift = this.icons.find(x => x.title === 'earshift').pathIcon;
    this.calendar = this.icons.find(x => x.title === 'calendar').pathIcon;
    this.road = this.icons.find(x => x.title === 'road2').pathIcon;
    this.mechanic = this.icons.find(x => x.title === 'mechanic').pathIcon;
    this.check = this.icons.find(x => x.title === 'check').pathIcon;

  }

  getVehicleDetails(){
    this._ap.getVehicleDetails(this.vehicleId).subscribe((response) => {
      if (response.result === 'OK'){
        console.log('vehicle data');
        this.vehicle = response.data;
        console.log(this.vehicle);
        console.log(response.msg);
        this.calcDiscount();
        this._ss.setTitle('Vehículo > ' + this.vehicle.brand + ' ' + this.vehicle.model + ' ' + this.vehicle.version);
      }else{
        console.log('Se ha producido un error');
        console.log(response.msg);
      }
    });
  }

  getImages(){

    this._ap.getVehicleImages(this.vehicleId).subscribe((response) => {
      if (response.result === 'OK'){
        console.log('vehicle images');
        console.log(response.data);
        this.images = response.data;
        console.log(this.images);
        console.log(response.msg);
      }else{
        console.log('Se ha producido un error');
        console.log(response.msg);
      }
    });
  }

  getEquipment(){

    this._ap.getVehicleEquipment(this.vehicleId).subscribe((response) => {
      if (response.result === 'OK'){
        console.log('vehicle equipment');
        console.log(response.data);
        this.equipments = response.data;
        console.log(response.msg);
      }else{
        console.log('Se ha producido un error');
        console.log(response.msg);
      }
    });
  }

  getExtras(){

    this._ap.getVehicleExtras(this.vehicleId).subscribe((response) => {
      if (response.result === 'OK'){
        console.log('vehicle Extras');
        console.log(response.data);
        this.extras = response.data;
        console.log(response.msg);
      }else{
        console.log('Se ha producido un error');
        console.log(response.msg);
      }
    });
  }

  getAccesories(){
    debugger;
    this._ap.getVehicleAccesories(this.vehicleId).subscribe((response) => {
      if (response.result === 'OK'){
        console.log('vehicle Accesories');
        console.log(response.data);
        this.accesories = response.data;
        console.log(response.msg);
      }else{
        console.log('Se ha producido un error');
        console.log(response.msg);
      }
    });
  }

  setActiveTabEquipment(result){
    this.activeTabEquipment = result;
  }

  setActiveTabExtras(result){
    this.activeTabExtras = result;
  }

  showThumbs(){
    this.thumbNavigation = !this.thumbNavigation;
  }

  to(id){
    console.log(id);
  }

  calcDiscount(){
    this.discount = this.vehicle.precio - this.vehicle.precio_oferta;
    this.percentage = (this.vehicle.precio_oferta * 100) / this.vehicle.precio;
    this.percentage = 100 - this.percentage.toFixed();
  }

}
