import { Component, OnInit, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AppService } from 'src/app/services/app.service';
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-promotion-slider',
  templateUrl: './promotion-slider.component.html',
  styleUrls: ['./promotion-slider.component.scss']
})
export class PromotionSliderComponent implements OnInit{

  @Input() promotionId;
  page;
  promotions = [];
  faPlus = faPlus;
  title = 'PROMOCIONES DESTACADAS';

  customOptions: OwlOptions = {
    autoplay: true,
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1 
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 4
      }
    },
    nav: false
  }

  constructor(
    private _ap: AppService,
    public _ss: SharingService
    ) { }

  ngOnInit(): void {
    this.getBrandPromotionFeatured();
    this.getFeaturedPromotions();
  }


  getBrandPromotionFeatured(){
    this._ap.getFeaturedBrandPromotions().subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        let promotions = response.data;
        promotions.forEach(promotion => {
          promotion.id_workshop = null;
          this.promotions.push(promotion);
        });
      }else{
        console.log(response.msg);
        console.log("Se ha producido un error");
      }
    });
  }

  

  getFeaturedPromotions(){
    this._ap.getFeaturedPromotions().subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        let promotions = response.data;
        promotions.forEach(promotion => {
          promotion.summary = null;
          promotion.id_brand = null;
          this.promotions.push(promotion);
        });
      }else{
        console.log(response.msg);
        console.log("Se ha producido un error");
      }
    });
  }

}
