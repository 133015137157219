// -----------------------------------------------------------------------------
// ENDPOINTS MULTISITE DIGITALIZAT
// -----------------------------------------------------------------------------

// FOOTER CONTACT
export const FOOTER_CONTACT = {
    title: "DATOS CONTACTOS",
    schedule: "Lunes a Viernes: 9:00 a 20:30 Sábados: 10:00 a 19:30",
    address: "Av. Laboral, Nº10 36650 - Pontevedra",
    phone: "+34 986 54 02 35",
    email: "correo@electronico"
        // AL TELEFONO Y EMAIL SE LE PODRÍA HACER UNA LLAMADA A LA CONSTANTE TOP BAR
}

//PAGE TYPES
export const PAGE_TYPES_IDS = {
    HOME_PAGE_TYPE_ID: 0,
    VEHICLE_PAGE_TYPE_ID: 1,
    SERVICE_PAGE_TYPE_ID: 2,
    PROMOTION_PAGE_TYPE_ID: 3,
    WHO_WE_ARE_PAGE_TYPE_ID: 4,
    CONTACT_PAGE_TYPE_ID: 5,
    RESERVATION_PAGE_TYPE_ID: 6,
    INSURANCE_PAGE_TYPE_ID: 7,
    POLICIES_PAGE_TYPE_ID: 9,
    TERMS_PAGE_TYPE_ID: 8,
    LEGAL_ADVICE_PAGE_TYPE_ID: 10
  }

// FOOTER_WORKSHOP
export const FOOTER_WORKSHOP = {
    social: [{
            id: 1,
            title: "Facebook",
            image: "../assets/footer_icons/facebook.svg",
            url: ""
        }, {
            id: 2,
            title: "Twitter",
            image: "../assets/footer_icons/twitter.svg",
            url: ""
        }, {
            id: 3,
            title: "Linkedin",
            image: "../assets/footer_icons/linkedin.svg",
            url: ""
        }]
        // iconos facebook, twitter y linkedin
}

export const BANNER = {
        title: "Calidad y servicio",
        subtitle: "tu vehiculo esta en buenas manos",
        image: "../assets/banners/banner_1.jpg",
        email: "Contáctenos",
        phone: "Llámenos ahora"
    }

export const BANNER_UP = {
    title: "Lideres en automoción",
    subtitle: "siempre a tu disposición",
    image: "../assets/banners/banner_page.jpg",
    email: "Contáctenos",
    phone: "Llámenos ahora"
}

export const BANNER_BELOW = {
    title: "Mantenimiento al día",
    subtitle: "de primera calidad",
    image: "../assets/banners/aceite.jpg",
    email: "Contáctenos",
    phone: "Llámenos ahora"
}

export const WORKSHOP_ICONS = [{
        title: "oil",
        pathIcon: "../assets/icons_workshop/oil.svg"
    }, {
        title: "temperature",
        pathIcon: "../assets/icons_workshop/002-temperature.svg"
    }, {
        title: "gas_station",
        pathIcon: "../assets/icons_workshop/003-gas station.svg"
    }, {
        title: "speedometer",
        pathIcon: "../assets/icons_workshop/004-dashboard.svg"
    }, {
        title: "speedometer_two",
        pathIcon: "../assets/icons_workshop/005-dashboard.svg"
    }, {
        title: "speedometer_three",
        pathIcon: "../assets/icons_workshop/006-dashboard.svg"
    }, {
        title: "speedometer_five",
        pathIcon: "../assets/icons_workshop/007-dashboard.svg"
    }, {
        title: "speedometer_six",
        pathIcon: "../assets/icons_workshop/008-dashboard.svg"
    }, {
        title: "parking",
        pathIcon: "../assets/icons_workshop/009-parking.svg"
    }, {
        title: "mechanic",
        pathIcon: "../assets/icons_workshop/010-mechanic.svg"
    }, {
        title: "belt",
        pathIcon: "../assets/icons_workshop/011-seat-bealt.svg"
    }, {
        title: "car",
        pathIcon: "../assets/icons_workshop/012-car.svg"
    }, {
        title: "car_two",
        pathIcon: "../assets/icons_workshop/013-car.svg"
    }, {
        title: "car_three",
        pathIcon: "../assets/icons_workshop/014-car.svg"
    }, {
        title: "car_four",
        pathIcon: "../assets/icons_workshop/015-car.svg"
    }, {
        title: "car_five",
        pathIcon: "../assets/icons_workshop/016-car.svg"
    }, {
        title: "car_six",
        pathIcon: "../assets/icons_workshop/017-car.svg"
    }, {
        title: "car_seven",
        pathIcon: "../assets/icons_workshop/016-car.svg"
    }, {
        title: "car_six",
        pathIcon: "../assets/icons_workshop/016-car.svg"
    }, {
        title: "car_six",
        pathIcon: "../assets/icons_workshop/016-car.svg"
    }, {
        title: "distribution",
        pathIcon: "../assets/icons_workshop/distribution.svg"
    }, {
        title: "color",
        pathIcon: "../assets/icons_workshop/081-color.svg"
    }, {
        title: "dgt",
        pathIcon: "../assets/icons_workshop/081-dgt.svg"
    }, {
        title: "tyre",
        pathIcon: "../assets/icons_workshop/tire.svg"
    }, {
        title: "lights",
        pathIcon: "../assets/icons_workshop/lights.svg"
    }, {
        title: "air",
        pathIcon: "../assets/icons_workshop/026-air-conditioner.svg"
    }, {
        title: "braking",
        pathIcon: "../assets/icons_workshop/brakes.svg"
    }, {
        title: "shocks",
        pathIcon: "../assets/icons_workshop/suspension.svg",
    }, {
        title: "battery",
        pathIcon: "../assets/icons_workshop/080-battery.svg",
    }, {
        title: "mechanic",
        pathIcon: "../assets/icons_workshop/mechanic.svg",
    }, {
        title: "bodywork",
        pathIcon: "../assets/icons_workshop/mechanic.svg",
    }, {
        title: "plus",
        pathIcon: "../assets/icons_workshop/plus.svg"
    },
    {
        title: "calendar",
        pathIcon: "../assets/icons_workshop/calendar.svg"
    }, {
        title: "earshift",
        pathIcon: "../assets/icons_workshop/048-gearshift.svg"
    }, {
        title: "engine",
        pathIcon: "../assets/icons_workshop/engine.svg"
    }, {
        title: "road",
        pathIcon: "../assets/icons_workshop/road.svg"
    }, {
        title: "road2",
        pathIcon: "../assets/icons_workshop/road.svg"
    },
    {
        title: "check",
        pathIcon: "../assets/icons_workshop/check.svg"
    },
    {
        title: "location",
        pathIcon: "../assets/icons_workshop/location.svg"
    },
    {
        title: "fuel",
        pathIcon: "../assets/icons_workshop/fuel.svg"
    }
];


export const VEHICLES =  [
    {
        id: 4,
        title: "FIAT MULTIPLA",
        image: "http://localhost/multisite/static/a3799018e0.webp",
        description: "",
        buttonText: "Visualizar",
        attributes: {
            brand:"RENAULT",
            model: "ARKANA E-TECH",
            version: "",
            fuelType: "Hibrido",
            kilometers: "0",
            transmision: "Automático",
            carDoors: "5 puertas",
            quarranty: "1 año",
            date: "2022-01-05",
            engine: "",
            color: "Negro",
            price: 28903,
            cv: "140cv",
            consumption: "Consumo. medio 4.9 litros, Cons. urbano 5.9 litros, Cons. extraurbano 4.3 litros"
        }
    },
        {
            id: 4,
            title: "FIAT MULTIPLA",
            image: "http://localhost/multisite/static/94e57228be.webp",
            description: "",
            buttonText: "Visualizar",
            attributes: {
                brand: "RENAULT",
                model: "CLIO E-TECH HIBRIDO",
                version: "1.6 TDI 130cv",
                fuelType: "Hibrido",
                kilometers: "45000",
                transmision: "Manual",
                carDoors: "5 puertas",
                quarranty: "1 año",
                date: "2021-03-22",
                engine: "",
                color: "Naranja",
                price: 21342,
                oferted_price: 20999,
                financed_price:25000,
                cv: "cv",
                consumption: "Consumo. medio 4.9 litros, Cons. urbano 5.9 litros, Cons. extraurbano 4.3 litros"
            }
    },
    {
        id: 4,
        title: "FIAT MULTIPLA",
        image: "http://localhost/multisite/static/7401c0e11f.webp",
        description: "",
        buttonText: "Visualizar",
        attributes: {
            brand:"RENAULT",
            model: "CAPTUR E-TECH",
            version: "",
            fuelType: "Hibrido",
            kilometers: "45000",
            transmision: "Automático",
            carDoors: "5 puertas",
            quarranty: "1 año",
            date: "2021-06-12",
            engine: "",
            color: "Naranja",
            price: 24964,
            cv: "140cv",
            consumption: "Consumo. medio 4.9 litros, Cons. urbano 5.9 litros, Cons. extraurbano 4.3 litros"
        }
    }
];