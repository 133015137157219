<div class="container-slider">
    <h1>VEHÍCULOS DESTACADOS</h1>
    <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let vehicle of vehicles">
            <div class="container-vehicles">
                <a [routerLink]="['/vehiculos', vehicle.id]">
                    <div class="vehicle-container-image">
                        <div class="vehicle-img" [ngStyle]="{'background-image':'url(' + this._ss.imgURL + vehicle.images[0] + ')'}"></div>
                        <div class="overlay">
                            <div class="ribbon" *ngIf="vehicle.offered_price"><span>OFERTA</span></div>
                            <div class="ribbon new" *ngIf=""><span>NUEVO</span></div>
                        </div>
                        <div class="overlay-cover">
                            <span class="icon">
                                <fa-icon [icon]="faPlus"></fa-icon>
                            </span>
                        </div>
                        <span *ngIf="vehicle.financed_price" class="financed">desde {{vehicle.financed_price}}€/ mes</span>
                    </div>
                </a>
                <div class="vehicle-body">
                    <div class="bar-title">
                        <h3 title="{{ vehicle.brand }} {{ vehicle.model }} {{ vehicle.version }}">
                            {{ vehicle.brand }} {{ vehicle.model }} {{ vehicle.version }}
                          </h3>                        <div class="price-container">
                            <span class="discounted" *ngIf="vehicle.offered_price">{{vehicle.price | currency:'EUR':'symbol':'3.0'}}</span>
                            <span *ngIf="vehicle.offered_price" class="discounted_price">{{vehicle.offered_price | currency:'EUR':'symbol':'3.0'}}</span>
                            <span *ngIf="!vehicle.offered_price" class="price">{{vehicle.price | currency:'EUR':'symbol':'3.0'}}</span>
                        </div>
                    </div>
                    <span class="line"></span>
                    <ul class="nav">

                        <li *ngIf="vehicle.transmission != ''">
                            <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="location"></div>
                            <span>{{vehicle.transmission}}</span>
                        </li>

                        <li *ngIf="vehicle.carDoors != ''">
                            <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="bodywork "></div>
                            <span>{{vehicle.carDoors}}</span>
                        </li>

                        <li *ngIf="vehicle.fuelType != ''">
                            <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="fuel"></div>
                            <span>{{vehicle.fuelType}}</span>
                        </li>

                        <li *ngIf="vehicle.kilometers != ''">
                            <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="kilometers"></div>
                            <span>{{vehicle.kilometers | number:'':'es-ES'}} km</span>
                        </li>
                        
                        <li *ngIf="vehicle.date != ''">
                            <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="calendar"></div>
                            <span>{{vehicle.date | date: 'yyyy'}}</span>
                        </li>

                    </ul>
                </div>
                <div class="vehicle-footer">
                    <a [routerLink]="['/vehiculos', vehicle.id]" class="primary-btn">{{buttonText}}</a>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>