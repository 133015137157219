import {Component, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {AppService} from 'src/app/services/app.service';
import {SharingService} from 'src/app/services/sharing.service';

@Component({
  selector: 'app-insurance-slider',
  templateUrl: './insurance-slider.component.html',
  styleUrls: ['./insurance-slider.component.scss']
})
export class InsuranceSliderComponent implements OnInit {
  partners = [];
  page = {title: ''};

  customOptions: OwlOptions = {
    autoplay: true,
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: false
  };

  constructor(
    private _ap: AppService,
    public _ss: SharingService
  ) {
  }

  ngOnInit(): void {
    this.page.title = 'ASEGURADORAS';
    this.getPartners();
  }

  getPartners() {
    this._ap.getPartners().subscribe((response) => {
      if (response.result === 'OK') {
        console.log(response.msg);
        console.log('cargando colaboeradores');
        this.partners = response.data;
      } else {
        console.log(response.msg);
        console.log('Se ha producido un error!');
      }
    });
  }

}
