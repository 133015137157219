import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-vehicle-calculator',
  templateUrl: './vehicle-calculator.component.html',
  styleUrls: ['./vehicle-calculator.component.scss']
})
export class VehicleCalculatorComponent implements OnInit {

  @Input() vehicle;


  crediya_importe = 10000;        // Importe del vehículo
  crediya_entrada = 0;    // Importe de la entrada por defecto
  crediya_marca = 7;      // Código de Marca del Vehículo
  crediya_modelo = 70;    // Código de Modelo del Vehículo
  crediya_age = 2018;            // Año de matriculación del Vehículo
  crediya_url = '';            // Imagen del vehículo


  constructor() {
  
   }

  ngOnInit(){
    this.crediya_importe = this.vehicle.attributes.price;
    this.crediya_age = this.vehicle.attributes.date;
    this.crediya_url = this.vehicle.image;
  }

}
