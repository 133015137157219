<app-banner-model-page [page]="page.id_page_type"></app-banner-model-page>
<!-- Contenedor imagenes, constantes
<div class="container">
    <div class="services" *ngFor="let service of vehicles">
        <img src="{{service.image}}" alt="iconos">
        <h5>{{service.title}}</h5>
        <p>{{service.description}}</p>
    </div>
</div>
-->
<div class="content">
    <!-- loader -->
    <div class="loader-box" *ngIf="_ss.loadingAppointmentStatus">
        <div class="loader"></div>
    </div>
    <!-- end loader-->
    <div *ngFor="let vehicle of vehicles | paginate: config">
        <div class="container-vehicles">
            <a [routerLink]="['/vehiculos', vehicle.id]">
                <div class="vehicle-container-image">
                    <div class="vehicle-img" [ngStyle]="{'background-image':'url(' + _ss.imgURL + vehicle.images[0] + ')'}"></div>
                    <div class="overlay">
                        <div class="ribbon" *ngIf="vehicle.offered_price"><span>OFERTA</span></div>
                        <div class="ribbon new" *ngIf=""><span>NUEVO</span></div>
                    </div>
                    <div class="overlay-cover">
                        <span class="icon">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                </span>
                    </div>
                    <span *ngIf="vehicle.financed_price" class="financed">desde {{vehicle.financed_price}}€ / mes</span>
                </div>
            </a>
            <div class="vehicle-body">
                <div class="bar-title">
                    <h3>{{vehicle.brand}} {{vehicle.model}} {{vehicle.version}}</h3>
                    <div class="price-container">
                        <span class="discounted" *ngIf="vehicle.offered_price">{{vehicle.price | currency:'EUR':'symbol':'3.0'}}</span>
                        <span *ngIf="vehicle.offered_price" class="discounted_price">{{vehicle.offered_price | currency:'EUR':'symbol':'3.0'}}</span>
                        <span *ngIf="!vehicle.offered_price" class="price">{{vehicle.price | currency:'EUR':'symbol':'3.0'}}</span>
                    </div>
                </div>
                <span class="line"></span>
                <ul class="nav">
                    <li *ngIf="vehicle.location != ''">
                        <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="location"></div>
                        <span>{{vehicle.location}}</span>
                    </li>

                    <li *ngIf="vehicle.bodywork != ''">
                        <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="bodywork "></div>
                        <span>{{vehicle.bodywork}}</span>
                    </li>

                    <li *ngIf="vehicle.fuel != ''">
                        <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="fuel "></div>
                        <span>{{vehicle.fuel}}</span>
                    </li>

                    <li *ngIf="vehicle.kilometers != ''">
                        <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="kilometers"></div>
                        <span>{{vehicle.kilometers | number:'':'es-ES'}} km</span>
                    </li>

                    <li *ngIf="vehicle.registration_date != ''">
                        <div class="vehicle-icon" aria-label="My icon" [inlineSVG]="calendar"></div>
                        <span>{{vehicle.registration_date | date: 'yyyy'}}</span>
                    </li>
                </ul>
            </div>
            <div class="vehicle-footer">
                <a [routerLink]="['/vehiculos', vehicle.id]" class="primary-btn">VER MÁS</a>
            </div>
        </div>
    </div>
</div>

<div style="text-align:center">
    <pagination-controls class="pagination" (pageChange)="pageChanged($event)" previousLabel="" nextLabel="">
    </pagination-controls>
</div>
