import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { SharingService } from 'src/app/services/sharing.service';
import { ActivatedRoute } from '@angular/router';
import { element } from 'protractor';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    // tslint:disable-next-line:variable-name
    private _ap: AppService,
    // tslint:disable-next-line:variable-name
    public _ss: SharingService,
    private activeRouter: ActivatedRoute
  ) {
    this.activeRouter.queryParamMap.subscribe(queryParams => {
      this._ss.acceskey = queryParams.get('key');
      console.log(this._ss.acceskey);
      this._ss.checkSiteMaintenance();
    });
  }


  banners = [];
  topBanner;
  banner = {};
  botBanner;

  ngOnInit(): void {
    this._ss.setTitle(this._ss.workShopConfig.site_description);
    this._ss.setCenterId('');
    this.banner = this._ap.getInfoBannerUp();
    this.getBanners();
  }

  getBanners(){
      this._ap.getWorkshopBanner().subscribe((response) => {
        if (response.result === 'OK'){
          this.banners = response.data;
          this.checkBanners();
        }else{
          console.log('Se ha producido un error!');
        }
      }, (err) => {
        console.log('Se ha producido un error!', err);
      });
  }

  checkBanners(){
    console.log(this.banners);
    if (this.banners.length > 0){
      this.banners.forEach((banner, index) => {
        switch (index){
          case 0:
          this.topBanner = this.banners[0];
          break;

          case 1:
          this.botBanner = this.banners[1];
          break;
        }
      });
    }
  }

}
