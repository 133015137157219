import { Injectable } from '@angular/core';
import { URL } from '../config';
import { SharingService } from './sharing.service';
import { HttpClient } from '@angular/common/http';
import { AppService } from './app.service';
import { PAGE_TYPES_IDS } from '../constants';
import { ActivatedRoute, Router } from '@angular/router';
import { Theme } from 'src/global/brands/theme';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService{
adminURL = URL.host_backend + URL.endpoint_admin;
publicURL = URL.host_backend + URL.endpoint_public;
domain;
pageType = PAGE_TYPES_IDS;

  constructor(
    // tslint:disable-next-line:variable-name
    private _ss: SharingService,
    // tslint:disable-next-line:variable-name
    private _http: HttpClient,
    // tslint:disable-next-line:variable-name
    private _ap: AppService,
    private router: Router,
    private theme: ThemeService,
  ) {
    this.domain = window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    // this.domain = 'webmotrio.driver360.es';
  }

  public loadConfig() {
    return this._http.post(this.publicURL + 'settings/', {domain: this.domain})
        .toPromise()
        .then((response: any) => {
            this._ss.workShopConfig = response.data[0];
            this._ss.setLocalStorage(JSON.stringify(this._ss.workShopConfig));
            console.log(this._ss.workShopConfig);
            this.setTheme(this._ss.workShopConfig.id_brand);
            this.getAvailablePages();
        })
        .catch((err: any) => {
            console.error(err);
        });
  }

  public getConfig() {
      return this._ss.workShopConfig;
  }

  setTheme(id){
    this.theme.setBrandTheme(id);
  }

  getAvailablePages(){
    this._ap.getWorkshopPages().subscribe((response) => {
      if (response.result === 'OK'){
        console.log(response.msg);
        const pages = response.data;
        console.log (pages);
        console.log(pages);
        pages.forEach((page: { id_page_type: number; status: number; active: number  }) => {
          const i = this._ss.workShopModules.findIndex(x => x.id === page.id_page_type);
          // tslint:disable-next-line:max-line-length
          if (i !== -1 && page.status === 1 && page.active === 1 || (page.active !== 1 && page.status === 1 && page.id_page_type === PAGE_TYPES_IDS.RESERVATION_PAGE_TYPE_ID)){
            this._ss.workShopModules.find(x => x.id === page.id_page_type).status = true;
          }else{
            this._ss.workShopModules.find(x => x.id === page.id_page_type).status = false;
          }
        });
        console.log('aqui los modulos');
        console.log(this._ss.workShopModules);
        this.checkOptionPages();
      }else{
        console.log('Se ha producido un error');
        console.log(response.msg);
      }
    });
  }

  checkOptionPages(){
    this._ss.navMenu = this._ss.workShopModules.filter(x => x.status === true);
    /* En los modulos tambien vienen las aseguradoras y las reservas
    los quitamos de la barra de menú de _ss.navMenuegación */
    this._ss.navMenu = this._ss.navMenu.filter((x: { id: number; }) => x.id !== this.pageType.INSURANCE_PAGE_TYPE_ID);
    this._ss.navMenu = this._ss.navMenu.filter((x: { id: number; }) => x.id !== this.pageType.RESERVATION_PAGE_TYPE_ID);
    this._ss.navMenu = this._ss.navMenu.filter((x: { id: number; }) => x.id !== this.pageType.TERMS_PAGE_TYPE_ID);
    this._ss.navMenu = this._ss.navMenu.filter((x: { id: number; }) => x.id !== this.pageType.LEGAL_ADVICE_PAGE_TYPE_ID);
    this._ss.navMenu = this._ss.navMenu.filter((x: { id: number; }) => x.id !== this.pageType.POLICIES_PAGE_TYPE_ID);
  }

}
