import { Component, OnInit } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-under-contruction',
  templateUrl: './under-contruction.component.html',
  styleUrls: ['./under-contruction.component.scss']
})
export class UnderContructionComponent implements OnInit {

  constructor(
    public _ss:SharingService,
    private router:Router
  ) { 
    this._ss.showHeader = false;
    this._ss.showFooter = false;
    this._ss.setTitle('En contrucción');
    if(this._ss.workShopConfig.status){
      this._ss.showHeader = true;
      this._ss.showFooter = true;
      this.router.navigateByUrl("/");
    }
  }

  ngOnInit(): void {
  }

}
