import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PAGE_TYPES_IDS } from 'src/app/constants';
import { AppService } from 'src/app/services/app.service';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-workshop-policies',
  templateUrl: './workshop-policies.component.html',
  styleUrls: ['./workshop-policies.component.scss']
})
export class WorkshopPoliciesComponent implements OnInit {

pageType = PAGE_TYPES_IDS;
id_page_type;
page;

  constructor(
    private router: Router,
    private _ap: AppService,
    private _ss: SharingService
  ) {
    this._ss.getLocalStorage();
    this._ss.checkSiteMaintenance();
    console.log('Carngado componente....');
    console.log(this.router.url);
    let url = this.router.url.slice(1, this.router.url.length);
    console.log(url);
    switch(url){
      case 'aviso-legal':
        this.id_page_type = this.pageType.LEGAL_ADVICE_PAGE_TYPE_ID;
        this._ss.setTitle('Aviso legal');
      break;
      case 'terminos-y-condiciones':
        this.id_page_type = this.pageType.TERMS_PAGE_TYPE_ID;
        this._ss.setTitle('Términos y condiciones');
      break;
      case 'politica-de-privacidad':
        this.id_page_type = this.pageType.POLICIES_PAGE_TYPE_ID;
        this._ss.setTitle('Política de privacidad');
      break;
    }
    console.log(this.id_page_type);
   }

  ngOnInit(): void {
    this._ap.getInfoPageDetails(this.id_page_type).subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response);
        console.log(response.msg);
        this.page = response.data[0];
        console.log(this.page);
      }else{
        console.log(response.msg);
        console.log("Se ha producido un error!");
      }
    })
  }

}
