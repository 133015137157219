import { Component, OnInit,Input } from '@angular/core';
import {SharingService} from "../../../../services/sharing.service";

@Component({
  selector: 'app-banner-model-page-below',
  templateUrl: './banner-model-page-below.component.html',
  styleUrls: ['./banner-model-page-below.component.scss']
})
export class BannerModelPageBelowComponent implements OnInit {

  @Input() banner;
  @Input() number;

  constructor(public _ss:SharingService)
  { }



  ngOnInit(): void {

  }




}
