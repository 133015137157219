<div *ngIf="banner" class="banner" [ngStyle]="{'background-image':'url('+ _ss.imgURL +  banner.image + ')'}">
    <div class="overlay">
        <div class=content>
            <h1>{{banner.title}}</h1>
            <p [innerHtml]="banner.description"></p>
            <div class="buttons">
                <a type="button" class="btn btn-primary btn-lg" id="email" *ngIf="_ss.workShopConfig.email" href="mailto:{{_ss.workShopConfig.email}}">CONTÁCTANOS</a>
                <a type="button" class="btn btn-default btn-lg" id="phone" *ngIf="_ss.workShopConfig.phone" href="tel:{{_ss.workShopConfig.phone}}">LLÁMANOS</a>
            </div>
        </div>
    </div>
</div>

<div *ngIf="banner == null && _ss.acceskey != null" class="banner">
    <div class="banner_alert">
        <span class="alert">Debe configurar el {{ number }} banner!</span>
    </div>
</div>
