import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-vehicle-shop-now',
  templateUrl: './vehicle-shop-now.component.html',
  styleUrls: ['./vehicle-shop-now.component.scss']
})
export class VehicleShopNowComponent implements OnInit {

@Input() vehicle;

  constructor() { }

  ngOnInit() {
    console.log('el vehiculo es:'+ this.vehicle);
    console.log(this.vehicle);
  }

}
