<div class="container-footer">
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <!-- APARTADO DE DATOS DE CONTACTOS -->
                <div class="footer-section contact col-sm-3 col-md-3">
                    <h4>DATOS DE CONTACTO</h4>
                    <!-- SITE -->
                    <div *ngIf="_ss.workShopConfig.site_title == '' && _ss.acceskey != null">
                        <span>Título sin configurar</span>
                    </div>

                    <div *ngIf="_ss.workShopConfig.site_title">
                        <span>{{_ss.workShopConfig.site_title}}</span>
                    </div>

                    <!-- HORARIO -->
                    <div *ngIf="_ss.workShopConfig.timetable == '' && _ss.acceskey != null">
                        <span>Horario sin configurar</span>
                    </div>

                    <div *ngIf="_ss.workShopConfig.timetable">
                        <span><fa-icon [icon]="faClock"></fa-icon> {{_ss.workShopConfig.timetable}}</span>
                    </div>


                    <!-- UBICACIÓN -->
                    <div *ngIf="_ss.workShopConfig.address == '' && _ss.acceskey != null">
                        <span >Dirección sin configurar</span>
                    </div>

                    <div *ngIf="_ss.workShopConfig.address">
                        <span><fa-icon [icon]="faMapMarkerAlt"></fa-icon> {{_ss.workShopConfig.address}}</span>
                    </div>

                    <!-- TELÉFONO -->
                    <div *ngIf="_ss.workShopConfig.phone == '' && _ss.acceskey != null">
                        <span>Teléfono sin configurar</span>
                    </div>

                    <div *ngIf="_ss.workShopConfig.phone">
                        <span><fa-icon [icon]="faPhone"></fa-icon></span>
                        <a href="tel:+{{_ss.workShopConfig.phone}}"> {{_ss.workShopConfig.phone}}</a><br>
                    </div>

                     <!-- CORREO -->

                    <div *ngIf="_ss.workShopConfig.email == '' && _ss.acceskey != null">
                        <span>Correo sin configurar</span>
                    </div>

                    <div *ngIf="_ss.workShopConfig.email">
                        <span><fa-icon [icon]="faEnvelope"></fa-icon></span>
                        <a href="mailto:{{_ss.workShopConfig.email}}"> {{_ss.workShopConfig.email}}</a>
                    </div>

                </div>

                <!-- APARTADO DE ENLACES -->
                <div class="footer-section link col-sm-3 col-md-3">
                    <h4>{{titlelinks}}</h4>
                    <div *ngFor="let url of _ss.navMenu">
                        <a [routerLink]="[url.url]">{{url.title}}</a>
                    </div>
                </div>

                <!-- APARTADO DE VEHÍCULOS DESTACADOS -->
                <div class="footer-section featured-vehicles col-sm-3 col-md-3" *ngIf="_ss.getModule(1)">
                    <h4>{{titlevehicles}}</h4>
                    <!-- SLIDER DE VEHICULOS DESTACADOS -->
                    <div class="vehicle-container">
                        <a [routerLink]="['/vehiculos', vehicle.id]" class="vehicle-box" *ngFor="let vehicle of vehicles">
                            <div class="vehicle-img" [ngStyle]="{'background-image':'url(' + _ss.imgURL + vehicle.images[0] + ')'}"></div>
                            <div class="overlay"></div>
                            <div class="overlay-cover">
                                <span class="icon">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                </span>
                            </div>
                        </a>
                    </div>

                </div>

                <!-- APARTADO DE TALLERES -->
                <div class="footer-section workshop col-sm-3 col-md-3">
                    <h4 *ngIf="_ss.workShopConfig.site_title != ''">{{_ss.workShopConfig.site_title}}</h4>
                    <h4 *ngIf="_ss.workShopConfig.site_title == '' && _ss.acceskey != null">Nombre del sitio sin configurar</h4>
                    <p *ngIf="_ss.workShopConfig.site_description != ''">{{_ss.workShopConfig.site_description}}</p>
                    <p *ngIf="_ss.workShopConfig.site_description == '' && _ss.acceskey != null">Descripción del sitio sin configurar</p>
                    <ul class="socials">
                        <li *ngFor="let social of socialNetworks">
                            <a *ngIf="social.status && social.link != ''" target="_blank" href="{{social.link}}">
                                <span>
                                    <span class="social-icon" [inlineSVG]="social.image"></span>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>

    <!-- BARRA INFERIOR -->
    <div id="footer-bottom">
        <div class="copyrigth">
            &copy; {{_ss.workShopConfig.site_title}} Copyright 2024.
        </div>

        <div class="kit-digital">
          <li><img src="../../../../../assets/kitdigital/kitdigital.png" alt="Finaciado por la unión europea" width="600" height="50"/></li>
        </div>

        <div class="information">
            <div>
                <a [routerLink]="['/aviso-legal']">Aviso Legal</a>
            </div>
            <div>
                <a [routerLink]="['/terminos-y-condiciones']">Términos y condiciones</a>
            </div>
            <div>
                <a [routerLink]="['/politica-de-privacidad']">Política de privacidad</a>
            </div>
        </div>

    </div>
</div>
