import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';
import { SharingService } from 'src/app/services/sharing.service';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit {

  @Input() centers;
  faPhone = faPhone;
  faEnvelope = faEnvelope;

  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow; // ViewChild para MapInfoWindow
  bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
  center: google.maps.LatLngLiteral = { lat: 41.399115, lng: 2.160962 };
  zoom = 5;

  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  };

  markers: google.maps.MarkerOptions[] = [];

  icon = {
    url: '../../assets/',
    scaledSize: new google.maps.Size(20, 20),
  };

  constructor(
    // tslint:disable-next-line:variable-name
    public _ss: SharingService
  ){ }

  ngOnInit(): void {
    console.log(this.centers);
    this.setMarkers();
  }

  ngAfterViewInit() {
    this.setBounds();
    this.map._googleMap.addListener('idle', () => {
      this.mapIdle();
    });
  }

  setMarkers() {
    this.markers = this.centers.map(center => ({
      position: { lat: center.latitude, lng: center.longitude },
      title: center.name,
      clickable: true,
      label: {
        text: center.name,
        color: 'black',
        fontSize: '12px',
        fontWeight: 'bold',
      },
      options: {
        icon: this.icon,
      }
    }));
  }

  setBounds() {
    console.log('cargando fitbounds de los centros');
    this.centers.forEach(center => {
      this.bounds.extend(new google.maps.LatLng(center.latitude, center.longitude));
    });
    this.map.fitBounds(this.bounds);
  }

  mapIdle() {
    console.log('idle');
  }

  showInfo(center) {
    this.infoWindow.open();
    console.log(`InfoWindow para centro con ID: ${center.id_driver360_center}`);
  }

  showAppointmentCenter(center) {
    console.log('Mostrando el centro');
    console.log(center);
    this._ss.setCenterId(center.id_driver360_center);
    setTimeout(() => {
      this._ss.appointmentStatus = true;
    }, 100);
  }
}
