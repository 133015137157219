import { Injectable } from '@angular/core';
import { BANNER, BANNER_BELOW, BANNER_UP } from '../constants';
import { URL } from '../config';
import { SharingService } from './sharing.service';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/timeout';
import { throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';




@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    // tslint:disable-next-line:variable-name
    public _ss: SharingService,
    private http: HttpClient,
  ) { }

  private publicURL = URL.host_backend + URL.endpoint_public;  // URL public end points
  private adminURL = URL.host_backend + URL.endpoint_admin; // URL admin end points
  private driverURL = URL.host_backend + URL.endpoint_driver; // URL driver end points
  private qcarURL = URL.host_backend + URL.endpoint_qcar; // URL qcar end points

  featuredPromotions;
  vehicleData;

  banner = BANNER;
  // tslint:disable-next-line:variable-name
  banner_up = BANNER_UP;
  // tslint:disable-next-line:variable-name
  banner_below = BANNER_BELOW;

  // section_who_we_are = SECTION_WHO_WE_ARE;

  private getHeadersPublic() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('api-key', '@?Uq?DKYLd3bY_e*N%L46YKQ%M!ZcwABKAS_%mRBy%KD8D7#dagx5*A*hv2UR3RwufLvraDnxYmS7m+dE!_nybvNg^bZgHQbZ=Yp#g#Y6yDgLY7H!dmH=ZEuZf#A9yRD^kLw8H8s9MGQxCCWLWHN6-CCFYY$@uC24EXp*gm3==bLWvM6MUm%p9kkSyhNqegv3@qW@DN%?ux4zcLCv$W8V=CbBJ4cS@XA%CBwEA=gW?6CKMUE^%ACB_&*EchqV#Sq');
    headers.append('Authorization', this._ss.getWorkshopSettings() ? this._ss.getWorkshopSettings().token : '');
    return headers;
  }

  // Método para Obtener las páginas activas
  getWorkshopPages(): Observable<any> {
    const workshopSettings = this._ss.getWorkshopSettings();
    const url = `${this.publicURL}/workshop/${workshopSettings.id_workshop}/brand/${workshopSettings.id_brand}/pages`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // No necesitas response.json() ya que HttpClient lo maneja automáticamente
      catchError(this.handleError)
    );
  }

  // Método para cargar el Id del taller en Qcar
  getWorkshopQcarCompanyId(): Observable<any> {
    const workshopSettings = this._ss.getWorkshopSettings();
    const url = `${this.adminURL}/workshop/${workshopSettings.id_workshop}`;

    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // HttpClient maneja automaticamente la respuesta como JSON
      catchError(this.handleError)
    );
  }

  // Método para obtener el banner
  getInfoBanner(){
    if (this.banner != null){
      console.log('Banner cargado correctamente');
      return this.banner;
    }else{
      console.log('No hay banner informativo');
    }
  }

    // Método para obtener el banner inferior
    getInfoBannerUp(){
      if (this.banner_up != null){
        console.log('Banner cargado correctamente');
        return this.banner_up;
      }else{
        console.log('No hay banner informativo');
      }
    }

  // Método para obtener el banner inferior
  getInfoBannerBelow(){
    if (this.banner_below != null){
      console.log('Banner cargado correctamente');
      return this.banner_below;
    }else{
      console.log('No hay banner informativo');
    }
  }

  // Cargar todos las versiones de un modelo de tecdoc
  getTecdocVersions(idBrand: string, idModel: string): Observable<any> {
    const url = `${this.driverURL}/brand/${idBrand}/model/${idModel}/versions`;

    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // HttpClient maneja automaticamente la respuesta como JSON
      catchError(this.handleError)
    );
  }

  // Cargar todos los modelos de una marca de tecdoc
  getTecdocModels(id: string): Observable<any> {
    const url = `${this.driverURL}/brand/${id}/models`;

    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // HttpClient maneja automáticamente la respuesta como JSON
      catchError(this.handleError)
    );
  }

  // Cargar todas las marcas de tecdoc
  getTecdocBrands(): Observable<any> {
    const url = `${this.driverURL}/brands/`;

    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // HttpClient maneja automáticamente la respuesta como JSON
      catchError(this.handleError)
    );
  }

  // Cargar los datos de una página
  getInfoPageDetails(id: number): Observable<any> {
    const url = `${this.publicURL}/pagedetail/${id}/workshop/${this._ss.getWorkshopSettings().id_workshop}/brand/${this._ss.getWorkshopSettings().id_brand}`;

    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),  // HttpClient maneja automáticamente la respuesta como JSON
      catchError(this.handleError)
    );
  }

  // Método para cargar los servicios de taller
  getWorkShopServices(): Observable<any> {
    const url = `${this.publicURL}/services/${this._ss.getWorkshopSettings().id_workshop}`;

    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response), // HttpClient maneja automáticamente la respuesta como JSON
      catchError(this.handleError)
    );
  }

  // Método para cargar vehículos destacados
  getFeaturedVehicles(): Observable<any> {
    const url = `${this.publicURL}/workshop/${this._ss.getWorkshopSettings().id_workshop}/brand/${this._ss.getWorkshopSettings().id_brand}/vehicles/featured`;

    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response), // HttpClient maneja automáticamente la respuesta como JSON
      catchError(this.handleError)
    );
  }

  // Método para cargar todos los vehículos de un taller
  getVehicles(): Observable<any> {
    const url = `${this.publicURL}/workshop/${this._ss.getWorkshopSettings().id_workshop}/brand/${this._ss.getWorkshopSettings().id_brand}/vehicles`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  // https://backmultisiteprod.driver360.es/api/v1/public/workshop/26/brand/3/vehicles/active

  // Método para cargar vehículos destacados
  getActiveVehicles(){
    const url = `${this.publicURL}/workshop/${this._ss.getWorkshopSettings().id_workshop}/brand/${this._ss.getWorkshopSettings().id_brand}/vehicles/active`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Método para obtener los detalles de un vehículo
  getVehicleDetails(id: string): Observable<any> {
    const url = `${this.publicURL}/vehicle/${id}`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Método para obtener las imagenes de un vehiculo
  getVehicleImages(id: string): Observable<any> {
    const url = `${this.publicURL}/vehicle/images/${id}`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Método para obtener los extras de un vehiculo
  getVehicleExtras(id: string): Observable<any> {
    const url = `${this.publicURL}/vehicle/extra/${id}`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Método para obtener los accesorios de un vehiculo
  getVehicleAccesories(id: string): Observable<any> {
    const url = `${this.publicURL}/vehicle/accesories/${id}`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Método para obtener el equipamiento básico
  getVehicleEquipment(id: string): Observable<any> {
    const url = `${this.publicURL}/vehicle/equipment/${id}`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Metodos de promociones
  getSectionPromotions(): Observable<any> {
    const url = `${this.publicURL}/promotions/available/${this._ss.workShopConfig.id_workshop}/brand/${this._ss.getWorkshopSettings().id_brand}`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Método para obtener los detalles de una promocion
  getPromotionsDetails(id: string): Observable<any> {
    const url = `${this.publicURL}/promotion/${id}`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Método para cargar las promociones destacadas
  getFeaturedPromotions(): Observable<any> {
    const url = `${this.publicURL}/promotions/featured/${this._ss.workShopConfig.id_workshop}/brand/${this._ss.getWorkshopSettings().id_brand}`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Método para obtener las aseguradoras
  getPartners(): Observable<any> {
    const url = `${this.publicURL}/workshop/${this._ss.workShopConfig.id_workshop}/brand/${this._ss.getWorkshopSettings().id_brand}/partners`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Método para comprobar si el vehículo ya existe en el taller y devolver sus datos
  checkWorkShopPlate(plate: string): Observable<any> {
    const url = `${this.driverURL}/plate/${plate}/workshop/${this._ss.workShopConfig.id_workshop}`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Método para cargar los centros del Workshop
  getWorkshopCenters(): Observable<any> {
    const url = `${this.publicURL}/workshop/${this._ss.workShopConfig.id_workshop}/centers`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Get Calendar Workshop
  getWorkShopCalendar(idCenter: string, firstdayweek: string, timeshift: number): Observable<any> {
    const url = `${this.driverURL}calendar`;
    const body = { id_center: idCenter, firstdayweek: firstdayweek, timeshift: timeshift };
    return this.http.put(url, body, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Método para realizar el resguardo de cita
  confirmWorkShopAppointment(booking: any): Observable<any> {
    const url = `${this.driverURL}booking/`;
    return this.http.post(url, { booking }, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Método para realizar el envio de email
  emailToWorkshopCenter(formdata: any): Observable<any> {
    const url = `${this.publicURL}/email/`;
    const body = {
      name: formdata.name,
      email: formdata.email,
      phone: formdata.phone,
      from: formdata.email,
      to: formdata.center,
      message: formdata.message
    };
    return this.http.post(url, body, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Método para obtener las redes sociales
  getSocialNetworks(): Observable<any> {
    const url = `${this.publicURL}/workshop/${this._ss.getWorkshopSettings().id_workshop}/brand/${this._ss.getWorkshopSettings().id_brand}/socialnetworks`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Método para obtener las promociones de marca
  getBrandPromotions(): Observable<any> {
    const url = `${this.publicURL}/brand/promotions/${this._ss.getWorkshopSettings().id_brand}`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Método para obtener el slider del taller
  getWorkshopSlider(): Observable<any> {
    const url = `${this.publicURL}/workshop/${this._ss.getWorkshopSettings().id_workshop}/brand/${this._ss.getWorkshopSettings().id_brand}/slider`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Método para obtener el banner activo del taller
  getWorkshopBanner(): Observable<any> {
    const url = `${this.publicURL}/workshop/${this._ss.getWorkshopSettings().id_workshop}/brand/${this._ss.getWorkshopSettings().id_brand}/banner/active`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Método para obtener las promociones destacadas de la marca
  getFeaturedBrandPromotions(): Observable<any> {
    const url = `${this.publicURL}/brand/promotions/featured/${this._ss.getWorkshopSettings().id_brand}`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

// Método para obtener el detalle de una promoción de la marca
  getBrandPromotionDetail(id: string): Observable<any> {
    const url = `${this.publicURL}/brand/promotion/${id}`;
    return this.http.get(url, { headers: this.getHeadersPublic() }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  private handleError(error: any): Observable<never> {
    console.error('An error occurred', error);
    return throwError(() => new Error(error.message || error));
  }

}




