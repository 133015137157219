import { Component, OnInit, Input } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import { AppService } from 'src/app/services/app.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { faTruckMonster } from '@fortawesome/free-solid-svg-icons';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss']
})
export class HomeSliderComponent implements OnInit {

  promotions = [];
  slider = [];
  height: number;
  videoId: string;
  youtubeURL = 'https://www.youtube.com/embed/KmPPOjo8fUg';
  sanitizedVideoURL: SafeResourceUrl;

  constructor(
    // tslint:disable-next-line:variable-name
    public _ss: SharingService,
    // tslint:disable-next-line:variable-name
    private _ap: AppService,
    private sanitizer: DomSanitizer
  ) { }

  customOptions: OwlOptions = {
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    loop: true,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<div class=\'nav-btn prev-slide\'></div>', '<div class=\'nav-btn next-slide\'></div>'],
    items: 1,
    autoHeight: true,
    autoWidth: true,
    nav: true
  };

  ngOnInit(): void {
    this.height = window.innerHeight - 120;
    this.sanitizedVideoURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.youtubeURL);

    this.getWorkshopSlider();
    this.getFeaturedBrandPromotions();
  }

  getWorkshopSlider(){
    console.log('Cargando slider....');
    this._ap.getWorkshopSlider().subscribe((response) => {
      console.log(response);
      if (response.result === 'OK'){
        console.log(response.msg);
        console.log(response.data);
        this.slider = response.data;
      }else{
        console.log(response.msg);
        console.log('Se ha producido un error!');
      }
    }, (err) => {
      console.log('Se ha producido un error: ' + err);
    });
  }



  getFeaturedBrandPromotions(){
    console.log('Cargando promociones....');
    this._ap.getFeaturedBrandPromotions().subscribe((response) => {
      console.log(response);
      if (response.result === 'OK'){
        console.log(response.msg);
        this.promotions = response.data;
      }else{
        console.log(response.msg);
        console.log('Se ha producido un error!');
      }
    }, (err) => {
      console.log('Se ha producido un error: ' + err);
    });
  }

  handlerOfSomeEvent(){

  }

}
